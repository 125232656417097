export const SERVICE = {
  configurator: 'configurator',
  landing: 'landing',
  admin: 'admin',
}

export const mobileBreakpoint = {
  thin: 768,
  veryThin: 414, // iPhone 6 Plus vertical
}

export const COOKIE = {
  token: 'wbt',
  locale: 'l',
  page: 'page',
  pageProtection: 'page-protection',
}

export const HEADER = {
  authorization: 'Authorization',
  pageProtection: 'x-page-protection',
  userAgent: 'user-agent',
  forwarded: 'x-forwarded-for',
}

export const PERIOD = {
  oneYear: 31536e3,
  neverExpires: new Date(2147483647000),
}

export const URLS = {
  root: '/',
  website_account: '/account',
  website_login: '/account/login',
  website_create_free_account: '/account/free/create-account',
  website_create_account: '/account/create-account',
  website_reset_password: '/account/reset-password',
  website_new_password: '/account/new-password/:token',
  website_stripe_redirect: '/account/stripe/redirect',
  website_about: '/account/about',

  website_root: '/website',
  website_config: '/website/config',

  website_config_style: '/website/config/style',
  website_edit_template: '/website/template',
  website_settings: `/website/settings`,
  website_seo: `/website/seo`,
  website_services: `/website/services`,
  website_billing: '/website/billing',
  website_billing_plan: '/website/billing?tab=billing',
  website_bootstrap: '/website/bootstrap',

  legal: '/legal',
  legal_terms: '/terms-conditions',
  legal_privacy: '/privacy',
  legal_imprint: '/imprint',
  legal_cookies: '/cookie-policy',

  website_statistics: '/website/statistics',
  website_integrations: '/website/integrations',
  website_integrations_details_ecwid: '/website/integrations/ecwid',
  website_integrations_details_tawk: '/website/integrations/tawk',
  website_integrations_details_livechat: '/website/integrations/livechat',
  website_integrations_details_calendly: '/website/integrations/calendly',
  website_integrations_details_helpspace: '/website/integrations/helpspace',
  website_integrations_details_chatra: '/website/integrations/chatra',
  website_integrations_details_tidio: '/website/integrations/tidio',
  website_integrations_details_optinly: '/website/integrations/optinly',
  website_integrations_details_blogger: '/website/integrations/blogger',
  website_integrations_details_weglot: '/website/integrations/weglot',
  website_integrations_details_typeform: '/website/integrations/typeform',
  website_integrations_details_jotform: '/website/integrations/jotform',
  website_integrations_details_dropinblog: '/website/integrations/dropinblog',
  website_integrations_details_custom: '/website/integrations/custom',
  website_statistics_subscription: `/website/statisticsSubscription`,
  landing_why: '/why-vsble',
  landing_features: '/features',
  landing_pricing: '/pricing',

  admin: '/admin',
  admin_users: '/admin/users-list',
}

export const CURRENCY = {
  EUR: 'EUR',
  USD: 'USD',
}

export const EU_COUNTRIES_CODE = {
  AT: 'AT',
  BE: 'BE',
  BG: 'BG',
  HU: 'HU',
  DE: 'DE',
  GR: 'GR',
  DK: 'DK',
  IE: 'IE',
  ES: 'ES',
  IT: 'IT',
  CY: 'CY',
  LV: 'LV',
  LT: 'LT',
  LU: 'LU',
  MT: 'MT',
  NL: 'NL',
  PL: 'PL',
  PT: 'PT',
  RO: 'RO',
  SK: 'SK',
  SI: 'SI',
  FI: 'FI',
  FR: 'FR',
  HR: 'HR',
  CZ: 'CZ',
  SE: 'SE',
  EE: 'EE',
  AX: 'AX',
  AL: 'AL',
  AD: 'AD',
  BY: 'BY',
  BA: 'BA',
  VA: 'VA',
  GB: 'GB',
  IS: 'IS',
  XK: 'XK',
  LI: 'LI',
  MK: 'MK',
  MD: 'MD',
  MC: 'MC',
  NO: 'NO',
  RU: 'RU',
  SM: 'SM',
  RS: 'RS',
  UA: 'UA',
  ME: 'ME',
  CH: 'CH',
  SJ: 'SJ',
}

export const WEBSITE_BLOCK = {
  SLIDER: 'SLIDER',
  ALBUM_LIST: 'ALBUM_LIST',
  ALBUM_DETAIL: 'ALBUM_DETAIL',
  MAIN: 'MAIN',
  CONTACTS: 'CONTACTS',
  HTML: 'HTML',
  TEXT: 'TEXT',
  PASSWORD_FORM: 'PASSWORD_FORM',
  COOKIE_PAGE: 'COOKIE_PAGE',
  EXPANDED_ALBUM_LIST: 'EXPANDED_ALBUM_LIST',
}

export const PORTFOLIO_TEMPLATES = {
  ANTON: 'anton',
  SOPHIE: 'sophie',
  NIKOLAI: 'nikolai',
  CATHERINE: 'catherine',
  PARIS: 'paris',
  BROOKLYN: 'brooklyn',
  NEW_YORK: 'newyork',
  SYDNEY: 'sydney',
  BERLIN: 'berlin',
  ANTWERPEN: 'antwerpen',
  BARCELONA: 'barcelona',
  PAVEL: 'pavel',
  MADRID: 'madrid',
  RIGA: 'riga',
  STOCKHOLM: 'stockholm',
  VIEN: 'vien',
  OSLO: 'oslo',
  ROTTERDAM: 'rotterdam',
  LOS_ANGELES: 'losangeles',
  MARSEILLE: 'marseille',
  SEVILLA: 'sevilla',
  MEMPHIS: 'memphis',
  SACRAMENTO: 'sacramento',
  EDMONTON: 'edmonton',
  GOTHENBURG: 'gothenburg',
  BARCELONAV2: 'barcelonav2',
  BERLINV2: 'berlinv2',
  LYON_V2: 'lyonv2',
}

export const PERMISSIONS_ALL_PORTFOLIO_TEMPLATES = 'ALL'

export const CONTACT_FORM_ALIGN = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
}

export const PORTFOLIO_TEMPLATES_ALIGN = {
  LEFT: 'LEFT',
  CENTER: 'CENTER',
}

export const WEBSITE_ALBUM_ITEM_TYPE = {
  PHOTO: 'PHOTO',
  VIDEO: 'VIDEO',
}

export const VIDEO_HOSTING = {
  VIMEO: 'VIMEO',
  YOUTUBE: 'YOUTUBE',
}

export const WEBSITE_MODE = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
}

export const PAGE_TYPES = {
  MAIN: 'MAIN_PAGE',
  FOOTER: 'FOOTER_PAGE',
}

export const ALBUM_TYPES = {
  MAIN: 'MAIN_ALBUM',
  FOOTER: 'FOOTER_ALBUM',
}

export const WEBSITE_PAGE_TYPES = {
  // old photo
  ALBUM_LIST: 'ALBUM_LIST',
  PHOTO: 'PHOTO',
  VIDEO: 'VIDEO',
  TEXT: 'TEXT',
  HTML: 'HTML',
  CONTACTS: 'CONTACTS',
  EXTERNAL_LINK: 'EXTERNAL_LINK',
}

export const WEBSITE_PAGE_BLOCK_TYPES = {
  ECWID: 'ecwid',
  SCRIPT: 'script',
}

export const WEBSITE_NAVIGATION_PAGE_TYPES = {
  ALBUM_LIST: 'ALBUM_LIST',
  CONTACTS: 'CONTACTS',
  HTML: 'HTML',
  TEXT: 'TEXT',
  EXTERNAL_LINK: 'EXTERNAL_LINK',
}

export const HOSTING_URLS = {
  VIMEO: 'https://vimeo.com/',
  YOUTUBE: 'https://www.youtube.com/watch?v=',
}

export const LOCALE = {
  en: 'en',
  de: 'de',
  fr: 'fr',
  ja: 'ja',
}

export const UPLOAD_STEPS = {
  UPLOADING: 'uploading',
  WAITING: 'waiting',
  READY: 'ready',
  GENERATING_PREVIEWS: 'generating_previews',
  GENERATING_THUMBNAILS: 'generating_thumbnails',
  COMPRESSING: 'compressing',
}

export const VAT_STATES = {
  VALID: 'valid',
  INVALID: 'invalid',
  UNKNOWN: 'unknown',
}

export const PAYMENT_STEPPER_TYPES = {
  BILLING_UPGRADE: 'BILLING_UPGRADE',
  CREATE_SUBSCRIPTION: 'CREATE_SUBSCRIPTION',
  RENEW_SUBSCRIPTION: 'RENEW_SUBSCRIPTION',
}

export const PAYMENT_TYPE = {
  CREDIT_CARD: 'CREDIT_CARD',
  INVOICE: 'INVOICE',
}

export const ABO_STATUS = {
  FREE: 'FREE',
  TRIALING: 'TRIALING',
  TRIAL_ENDED: 'TRIAL_ENDED',
  PAST_DUE: 'PAST_DUE',
  UNPAID: 'UNPAID',
  CANCELLED: 'CANCELLED',
  ACTIVE: 'ACTIVE',
  LIFETIME: 'LIFETIME',
}

export const PLAN_TYPE = {
  FREE: 'FREE',
  PRO: 'PRO',
  PROMAX: 'PROMAX',
  PROLITE: 'PROLITE',
}

export const PREMIUM_STATUSES = {
  DNS_CHECKING: 'DNS_CHECKING',
  DNS_CHECKING_FAILED: 'DNS_CHECKING_FAILED',
  DNS_VALID: 'DNS_VALID',
  CERT_REQUEST_FAILED: 'CERT_REQUEST_FAILED',
  READY: 'READY',
  ACTIVE: 'ACTIVE',
  FAILURE: 'FAILURE',
  DELETE: 'DELETE',
  READY_FOR_CONF: 'READY_FOR_CONF',
  READY_FOR_CERT: 'READY_FOR_CERT',
  ACTIVE_WARNING: 'ACTIVE_WARNING',
}

export const PREMIUM_FAILURE_CODE = {
  DNS_CHECKING_WRONG_IPV4: 'DNS_CHECKING_WRONG_IPV4',
  DNS_CHECKING_CONSTRAINT_IPV6: 'DNS_CHECKING_CONSTRAINT_IPV6',
  DNS_CHECKING_WRONG_IPV4_AND_CONSTRAINT_IPV6: 'DNS_CHECKING_WRONG_IPV4_AND_CONSTRAINT_IPV6',
  DNS_CHECKING_UNREGISTERED_DOMAIN: 'DNS_CHECKING_UNREGISTERED_DOMAIN',
  GENERIC_ERROR: 'GENERIC_ERROR',
}

export const BLOCK_PROPERTY_KEYS = {
  userPhoto: 'userPhoto',
  userPhotoMediaUrl: 'userPhotoMediaUrl',
  userPhotoText: 'userPhotoText',
  contactFormAlign: 'contactFormAlign',
  contactFormLang: 'contactFormLang',
  content: 'content',
  align: 'align',

  // album list
  rowsDesktop: 'rowsDesktop',
  modeDesktop: 'modeDesktop',
  titleDescriptionPositionDesktop: 'titleDescriptionPositionDesktop',
  rowsMobile: 'rowsMobile',
  modeMobile: 'modeMobile',
  titleDescriptionPositionMobile: 'titleDescriptionPositionMobile',
  imageCaptionsMobile: 'imageCaptionsMobile',
  imageCaptionsDesktop: 'imageCaptionsDesktop',

  albumRowsDesktop: 'albumRowsDesktop',
  albumModeDesktop: 'albumModeDesktop',
  albumTitleDescriptionPositionDesktop: 'albumTitleDescriptionPositionDesktop',
  albumRowsMobile: 'albumRowsMobile',
  albumModeMobile: 'albumModeMobile',
  albumTitleDescriptionPositionMobile: 'albumTitleDescriptionPositionMobile',
  albumImageCaptionsDesktop: 'albumImageCaptionsDesktop',
  albumImageCaptionsMobile: 'albumImageCaptionsMobile',
  albumSideDesktop: 'albumSideDesktop',
  albumSideMobile: 'albumSideMobile',
}

export const FEATURE_PROPERTY_KEYS = {
  pdf: 'pdf',
  pdfLogoSize: 'pdf_logo_size',
  slider: 'slider',
  font: 'font',
  footer: 'footer',
  logo: 'logo',
  logoMediaUrl: 'logoMediaUrl',
  header: 'header',
  subheader: 'subheader',
  transitionSpeed: 'transitionSpeed',
  album: 'album',
  objectFit: 'objectFit',
  sliderType: 'sliderType',
  sliderVideoUrl: 'sliderVideoUrl',
  sliderVideoProvider: 'sliderVideoProvider',
  logoColor: 'logoColor',
  navigationColor: 'navigationColor',
  albumTitleColor: 'albumTitleColor',
  albumDescriptionColor: 'albumDescriptionColor',
  style: 'style',
  contactForm: 'contactForm',
  contactFormLang: 'contactFormLang',
  contactFormAlign: 'contactFormAlign',
  openGraph: 'open_graph',
  openGraphImageMediaUrl: 'open_graph_imageMediaUrl',
  openGraphImage: 'open_graph_image',
  LOADER_ANIMATION_CUSTOM: 'LOADER_ANIMATION_CUSTOM',
  LOADER_ANIMATION_VARIANT: 'LOADER_ANIMATION_VARIANT',
  LOADER_ANIMATION_COLOR: 'LOADER_ANIMATION_COLOR',
  FOOTER_OVER_SLIDESHOW: 'FOOTER_OVER_SLIDESHOW',
  FOOTER_HIDDEN: 'FOOTER_HIDDEN',
  SCROLLABLE_HEADER: 'SCROLLABLE_HEADER',
}

export const OBJECT_FIT_KEYS = {
  contains: 'contains',
  cover: 'cover',
}

export const SLIDER_TYPE_KEYS = {
  photo: 'photo',
  video: 'video',
}

export const LOCATION_TYPE = {
  MAIN_PAGE: 'MAIN_PAGE',
  DETAILS_PAGE: 'DETAILS_PAGE',
  UNKNOWN_PAGE: 'UNKNOWN_PAGE',
}

export const CONTACT_FORM_LOCALE = {
  en: 'en',
  de: 'de',
  fr: 'fr',
  ja: 'ja',
  sp: 'sp',
  ru: 'ru',
}

export const STYLE_FEATURE_PROPERTIES = {
  HEADER_LINKS_COLOR: 'HEADER_LINKS_COLOR',
  HEADER_LINKS_SIZE: 'HEADER_LINKS_SIZE',
  HEADER_LINKS_PADDING_TOP: 'HEADER_LINKS_PADDING_TOP',
  HEADER_LINKS_PADDING_BOTTOM: 'HEADER_LINKS_PADDING_BOTTOM',
  HEADER_LINKS_FONT_NAME: 'HEADER_LINKS_FONT_NAME',
  HEADER_LINKS_FONT_ID: 'HEADER_LINKS_FONT_ID',
  HEADER_LINKS_FONT_SOURCE: 'HEADER_LINKS_FONT_SOURCE',
  HEADER_LINKS_STYLE: 'HEADER_LINKS_STYLE',
  HEADER_LINKS_FONT_WEIGHT: 'HEADER_LINKS_FONT_WEIGHT',
  HEADER_LOGO_COLOR: 'HEADER_LOGO_COLOR',
  HEADER_LOGO_SIZE: 'HEADER_LOGO_SIZE',
  HEADER_LOGO_FONT_WEIGHT: 'HEADER_LOGO_FONT_WEIGHT',
  HEADER_DESKTOP_LOGO_MARGIN_TOP: 'HEADER_DESKTOP_LOGO_MARGIN_TOP',
  HEADER_DESKTOP_LOGO_MARGIN_BOTTOM: 'HEADER_DESKTOP_LOGO_MARGIN_BOTTOM',
  HEADER_MOBILE_CUSTOM_LOGO_TOP_PADDING: 'HEADER_MOBILE_CUSTOM_LOGO_TOP_PADDING',
  HEADER_MOBILE_CUSTOM_LOGO_BOTTOM_PADDING: 'HEADER_MOBILE_CUSTOM_LOGO_BOTTOM_PADDING',
  HEADER_LOGO_MARGIN_TOP: 'HEADER_LOGO_MARGIN_TOP',
  HEADER_LOGO_MARGIN_BOTTOM: 'HEADER_LOGO_MARGIN_BOTTOM',
  HEADER_LOGO_FONT_NAME: 'HEADER_LOGO_FONT_NAME',
  HEADER_LOGO_FONT_ID: 'HEADER_LOGO_FONT_ID',
  HEADER_LOGO_FONT_SOURCE: 'HEADER_LOGO_FONT_SOURCE',
  HEADER_SUBLOGO_COLOR: 'HEADER_SUBLOGO_COLOR',
  HEADER_SUBLOGO_SIZE: 'HEADER_SUBLOGO_SIZE',
  HEADER_SUBLOGO_FONT_NAME: 'HEADER_SUBLOGO_FONT_NAME',
  HEADER_SUBLOGO_FONT_ID: 'HEADER_SUBLOGO_FONT_ID',
  HEADER_SUBLOGO_FONT_SOURCE: 'HEADER_SUBLOGO_FONT_SOURCE',
  HEADER_SUBLOGO_FONT_WEIGHT: 'HEADER_SUBLOGO_FONT_WEIGHT',
  HEADER_SUBLOGO_MARGIN_TOP: 'HEADER_SUBLOGO_MARGIN_TOP',
  HEADER_SUBLOGO_MARGIN_BOTTOM: 'HEADER_SUBLOGO_MARGIN_BOTTOM',
  HEADER_CUSTOM_LOGO_TOP_PADDING: 'HEADER_CUSTOM_LOGO_TOP_PADDING',
  HEADER_CUSTOM_LOGO_BOTTOM_PADDING: 'HEADER_CUSTOM_LOGO_BOTTOM_PADDING',
  HEADER_DESKTOP_TYPE: 'HEADER_DESKTOP_TYPE',
  HEADER_DESKTOP_BURGER_STYLE: 'HEADER_DESKTOP_BURGER_STYLE',
  HEADER_DESKTOP_LAYOUT_ALIGN: 'HEADER_DESKTOP_LAYOUT_ALIGN',
  HEADER_DESKTOP_PADDING_TOP: 'HEADER_DESKTOP_PADDING_TOP',
  HEADER_DESKTOP_PADDING_BOTTOM: 'HEADER_DESKTOP_PADDING_BOTTOM',
  HEADER_BACKGROUND_COLOR: 'HEADER_BACKGROUND_COLOR',
  HEADER_DESKTOP_BURGER_TEXT_ID: 'HEADER_DESKTOP_BURGER_TEXT_ID',
  HEADER_DESKTOP_BURGER_TEXT_NAME: 'HEADER_DESKTOP_BURGER_TEXT_NAME',
  HEADER_DESKTOP_BURGER_TEXT_SIZE: 'HEADER_DESKTOP_BURGER_TEXT_SIZE',
  HEADER_DESKTOP_BURGER_TEXT_COLOR: 'HEADER_DESKTOP_BURGER_TEXT_COLOR',
  HEADER_DESKTOP_BURGER_TEXT_WEIGHT: 'HEADER_DESKTOP_BURGER_TEXT_WEIGHT',
  HEADER_BACKGROUND_OPACITY: 'HEADER_BACKGROUND_OPACITY',
  HEADER_MOBILE_BURGER_TEXT_SIZE: 'HEADER_MOBILE_BURGER_TEXT_SIZE',
  HEADER_MOBILE_BURGER_ICON_COLOR: 'HEADER_MOBILE_BURGER_ICON_COLOR',
  HEADER_MOBILE_BURGER_TEXT_COLOR: 'HEADER_MOBILE_BURGER_TEXT_COLOR',
  HEADER_MOBILE_LOGO_SIZE: 'HEADER_MOBILE_LOGO_SIZE',
  HEADER_MOBILE_LOGO_MARGIN_TOP: 'HEADER_MOBILE_LOGO_MARGIN_TOP',
  HEADER_MOBILE_LOGO_MARGIN_BOTTOM: 'HEADER_MOBILE_LOGO_MARGIN_BOTTOM',
  HEADER_MOBILE_PADDING_TOP: 'HEADER_MOBILE_PADDING_TOP',
  HEADER_MOBILE_PADDING_BOTTOM: 'HEADER_MOBILE_PADDING_BOTTOM',
  HEADER_MOBILE_SUBLOGO_SIZE: 'HEADER_MOBILE_SUBLOGO_SIZE',
  HEADER_MOBILE_SUBLOGO_MARGIN_TOP: 'HEADER_MOBILE_SUBLOGO_MARGIN_TOP',
  HEADER_MOBILE_SUBLOGO_MARGIN_BOTTOM: 'HEADER_MOBILE_SUBLOGO_MARGIN_BOTTOM',
  HEADER_MOBILE_LAYOUT_ALIGN: 'HEADER_MOBILE_LAYOUT_ALIGN',
  HEADER_MOBILE_BURGER_STYLE: 'HEADER_MOBILE_BURGER_STYLE',
  HEADER_MOBILE_BURGER_TEXT_WEIGHT: 'HEADER_MOBILE_BURGER_TEXT_WEIGHT',
  HEADER_MOBILE_BURGER_TEXT_NAME: 'HEADER_MOBILE_BURGER_TEXT_NAME',
  HEADER_MOBILE_BURGER_TEXT_ID: 'HEADER_MOBILE_BURGER_TEXT_ID',
  BURGER_BACKGROUND_COLOR: 'BURGER_BACKGROUND_COLOR',
  BURGER_CLOSE_ICON_COLOR: 'BURGER_CLOSE_ICON_COLOR',
  BURGER_NAVIGATION_LINKS_STYLE: 'BURGER_NAVIGATION_LINKS_STYLE',
  BURGER_DESKTOP_NAVIGATION_LAYOUT_ALIGN: 'BURGER_DESKTOP_NAVIGATION_LAYOUT_ALIGN',
  BURGER_MOBILE_NAVIGATION_LAYOUT_ALIGN: 'BURGER_MOBILE_NAVIGATION_LAYOUT_ALIGN',
  BURGER_NAVIGATION_LINKS_FONT_NAME: 'BURGER_NAVIGATION_LINKS_FONT_NAME',
  BURGER_NAVIGATION_LINKS_FONT_ID: 'BURGER_NAVIGATION_LINKS_FONT_ID',
  BURGER_NAVIGATION_LINKS_FONT_WEIGHT: 'BURGER_NAVIGATION_LINKS_FONT_WEIGHT',
  BURGER_DESKTOP_NAVIGATION_LINKS_FONT_SIZE: 'BURGER_DESKTOP_NAVIGATION_LINKS_FONT_SIZE',
  BURGER_MOBILE_NAVIGATION_LINKS_FONT_SIZE: 'BURGER_MOBILE_NAVIGATION_LINKS_FONT_SIZE',
  BURGER_MOBILE_NAVIGATION_LINKS_TOP_PADDING: 'BURGER_MOBILE_NAVIGATION_LINKS_TOP_PADDING',
  BURGER_NAVIGATION_LINKS_COLOR: 'BURGER_NAVIGATION_LINKS_COLOR',
  BURGER_DESKTOP_NAVIGATION_LINKS_TOP_PADDING: 'BURGER_DESKTOP_NAVIGATION_LINKS_TOP_PADDING',
  BURGER_DESKTOP_NAVIGATION_LINKS_BOTTOM_PADDING: 'BURGER_DESKTOP_NAVIGATION_LINKS_BOTTOM_PADDING',
  BURGER_MOBILE_NAVIGATION_LINKS_BOTTOM_PADDING: 'BURGER_MOBILE_NAVIGATION_LINKS_BOTTOM_PADDING',
  BURGER_LOGO_COLOR: 'BURGER_LOGO_COLOR',
  BURGER_SUBLOGO_COLOR: 'BURGER_SUBLOGO_COLOR',
  BURGER_FOOTER_COLOR: 'BURGER_FOOTER_COLOR',
  BURGER_FOOTER_SOCIAL_ICON_COLOR: 'BURGER_FOOTER_SOCIAL_ICON_COLOR',
  BURGER_MOBILE_BACKGROUND_COLOR: 'BURGER_MOBILE_BACKGROUND_COLOR',
  BURGER_MOBILE_CLOSE_ICON_COLOR: 'BURGER_MOBILE_CLOSE_ICON_COLOR',
  BURGER_MOBILE_NAVIGATION_LINKS_FONT_WEIGHT: 'BURGER_MOBILE_NAVIGATION_LINKS_FONT_WEIGHT',
  BURGER_MOBILE_NAVIGATION_LINKS_FONT_NAME: 'BURGER_MOBILE_NAVIGATION_LINKS_FONT_NAME',
  BURGER_MOBILE_NAVIGATION_LINKS_FONT_ID: 'BURGER_MOBILE_NAVIGATION_LINKS_FONT_ID',
  BURGER_MOBILE_NAVIGATION_LINKS_STYLE: 'BURGER_MOBILE_NAVIGATION_LINKS_STYLE',
  BURGER_MOBILE_NAVIGATION_LINKS_COLOR: 'BURGER_MOBILE_NAVIGATION_LINKS_COLOR',
  BURGER_MOBILE_LOGO_COLOR: 'BURGER_MOBILE_LOGO_COLOR',
  BURGER_MOBILE_SUBLOGO_COLOR: 'BURGER_MOBILE_SUBLOGO_COLOR',
  BURGER_MOBILE_FOOTER_COLOR: 'BURGER_MOBILE_FOOTER_COLOR',
  BURGER_MOBILE_FOOTER_SOCIAL_ICON_COLOR: 'BURGER_MOBILE_FOOTER_SOCIAL_ICON_COLOR',

  SCROLLABLE_HEADER: 'SCROLLABLE_HEADER',
  HEADER_DESKTOP_BURGER_POSITION: 'HEADER_DESKTOP_BURGER_POSITION',
  FOOTER_LAYOUT_ALIGN: 'FOOTER_LAYOUT_ALIGN',
  FOOTER_MOBILE_LAYOUT_ALIGN: 'FOOTER_MOBILE_LAYOUT_ALIGN',
  FOOTER_COLOR: 'FOOTER_COLOR',
  FOOTER_SIZE: 'FOOTER_SIZE',
  FOOTER_MOBILE_SIZE: 'FOOTER_MOBILE_SIZE',
  FOOTER_FONT_NAME: 'FOOTER_FONT_NAME',
  FOOTER_FONT_ID: 'FOOTER_FONT_ID',
  FOOTER_FONT_WEIGHT: 'FOOTER_FONT_WEIGHT',
  FOOTER_BACKGROUND_COLOR: 'FOOTER_BACKGROUND_COLOR',
  FOOTER_BACKGROUND_OPACITY: 'FOOTER_BACKGROUND_OPACITY',
  FOOTER_FONT_SOURCE: 'FOOTER_FONT_SOURCE',
  FOOTER_LINK_STYLE: 'FOOTER_LINK_STYLE',
  FOOTER_SHOW_YEAR: 'FOOTER_SHOW_YEAR',
  FOOTER_SHOW_DOMAIN_TITLE: 'FOOTER_SHOW_DOMAIN_TITLE',
  FOOTER_OVER_SLIDESHOW: 'FOOTER_OVER_SLIDESHOW',
  FOOTER_HIDDEN: 'FOOTER_HIDDEN',
  FOOTER_ICON_TYPE: 'FOOTER_ICON_TYPE',
  FOOTER_SOCIAL_ICON_COLOR: 'FOOTER_SOCIAL_ICON_COLOR',
  CONTACTS_COLOR: 'CONTACTS_COLOR',
  CONTACTS_SIZE: 'CONTACTS_SIZE',
  CONTACTS_FONT_WEIGHT: 'CONTACTS_FONT_WEIGHT',
  CONTACTS_FONT_NAME: 'CONTACTS_FONT_NAME',
  CONTACTS_FONT_ID: 'CONTACTS_FONT_ID',
  CONTACTS_FONT_SOURCE: 'CONTACTS_FONT_SOURCE',
  ALBUM_TITLE_COLOR: 'ALBUM_TITLE_COLOR',
  ALBUM_TITLE_SIZE: 'ALBUM_TITLE_SIZE',
  ALBUM_TITLE_FONT_WEIGHT: 'ALBUM_TITLE_FONT_WEIGHT',
  ALBUM_TITLE_FONT_NAME: 'ALBUM_TITLE_FONT_NAME',
  ALBUM_TITLE_FONT_ID: 'ALBUM_TITLE_FONT_ID',
  ALBUM_TITLE_FONT_SOURCE: 'ALBUM_TITLE_FONT_SOURCE',
  ALBUM_DESCRIPTION_COLOR: 'ALBUM_DESCRIPTION_COLOR',
  ALBUM_DESCRIPTION_SIZE: 'ALBUM_DESCRIPTION_SIZE',
  ALBUM_DESCRIPTION_FONT_WEIGHT: 'ALBUM_DESCRIPTION_FONT_WEIGHT',
  ALBUM_DESCRIPTION_FONT_NAME: 'ALBUM_DESCRIPTION_FONT_NAME',
  ALBUM_DESCRIPTION_FONT_ID: 'ALBUM_DESCRIPTION_FONT_ID',
  ALBUM_DESCRIPTION_FONT_SOURCE: 'ALBUM_DESCRIPTION_FONT_SOURCE',
  ALBUM_PHOTO_NOTE_COLOR: 'ALBUM_PHOTO_NOTE_COLOR',
  ALBUM_PHOTO_NOTE_SIZE: 'ALBUM_PHOTO_NOTE_SIZE',
  ALBUM_PHOTO_NOTE_FONT_WEIGHT: 'ALBUM_PHOTO_NOTE_FONT_WEIGHT',
  ALBUM_PHOTO_NOTE_FONT_NAME: 'ALBUM_PHOTO_NOTE_FONT_NAME',
  ALBUM_PHOTO_NOTE_FONT_SOURCE: 'ALBUM_PHOTO_NOTE_FONT_SOURCE',
  ALBUM_PHOTO_NOTE_FONT_ID: 'ALBUM_PHOTO_NOTE_FONT_ID',

  ALBUM_MOBILE_TITLE_SIZE: 'ALBUM_MOBILE_TITLE_SIZE',
  ALBUM_MOBILE_DESCRIPTION_SIZE: 'ALBUM_MOBILE_DESCRIPTION_SIZE',
  ALBUM_MOBILE_PHOTO_NOTE_SIZE: 'ALBUM_MOBILE_PHOTO_NOTE_SIZE',

  ALBUM_LIST_TITLE_COLOR: 'ALBUM_LIST_TITLE_COLOR',
  ALBUM_LIST_TITLE_SIZE: 'ALBUM_LIST_TITLE_SIZE',
  ALBUM_LIST_TITLE_FONT_WEIGHT: 'ALBUM_LIST_TITLE_FONT_WEIGHT',
  ALBUM_LIST_TITLE_FONT_NAME: 'ALBUM_LIST_TITLE_FONT_NAME',
  ALBUM_LIST_TITLE_FONT_ID: 'ALBUM_LIST_TITLE_FONT_ID',
  ALBUM_LIST_TITLE_FONT_SOURCE: 'ALBUM_LIST_TITLE_FONT_SOURCE',
  ALBUM_LIST_DESCRIPTION_COLOR: 'ALBUM_LIST_DESCRIPTION_COLOR',
  ALBUM_LIST_DESCRIPTION_SIZE: 'ALBUM_LIST_DESCRIPTION_SIZE',
  ALBUM_LIST_DESCRIPTION_FONT_WEIGHT: 'ALBUM_LIST_DESCRIPTION_FONT_WEIGHT',
  ALBUM_LIST_DESCRIPTION_FONT_NAME: 'ALBUM_LIST_DESCRIPTION_FONT_NAME',
  ALBUM_LIST_DESCRIPTION_FONT_ID: 'ALBUM_LIST_DESCRIPTION_FONT_ID',
  ALBUM_LIST_DESCRIPTION_FONT_SOURCE: 'ALBUM_LIST_DESCRIPTION_FONT_SOURCE',

  ALBUM_LIST_MOBILE_TITLE_SIZE: 'ALBUM_LIST_MOBILE_TITLE_SIZE',
  ALBUM_LIST_MOBILE_DESCRIPTION_SIZE: 'ALBUM_LIST_MOBILE_DESCRIPTION_SIZE',

  PHOTOS_TITLE_COLOR: 'PHOTOS_TITLE_COLOR',
  PHOTOS_TITLE_SIZE: 'PHOTOS_TITLE_SIZE',
  PHOTOS_TITLE_FONT_WEIGHT: 'PHOTOS_TITLE_FONT_WEIGHT',
  PHOTOS_TITLE_FONT_NAME: 'PHOTOS_TITLE_FONT_NAME',
  PHOTOS_TITLE_FONT_ID: 'PHOTOS_TITLE_FONT_ID',
  PHOTOS_TITLE_FONT_SOURCE: 'PHOTOS_TITLE_FONT_SOURCE',
  PHOTOS_DESCRIPTION_COLOR: 'PHOTOS_DESCRIPTION_COLOR',
  PHOTOS_DESCRIPTION_SIZE: 'PHOTOS_DESCRIPTION_SIZE',
  PHOTOS_DESCRIPTION_FONT_WEIGHT: 'PHOTOS_DESCRIPTION_FONT_WEIGHT',
  PHOTOS_DESCRIPTION_FONT_NAME: 'PHOTOS_DESCRIPTION_FONT_NAME',
  PHOTOS_DESCRIPTION_FONT_ID: 'PHOTOS_DESCRIPTION_FONT_ID',
  PHOTOS_DESCRIPTION_FONT_SOURCE: 'PHOTOS_DESCRIPTION_FONT_SOURCE',
  PHOTOS_PHOTO_NOTE_COLOR: 'PHOTOS_PHOTO_NOTE_COLOR',
  PHOTOS_PHOTO_NOTE_SIZE: 'PHOTOS_PHOTO_NOTE_SIZE',
  PHOTOS_PHOTO_NOTE_FONT_WEIGHT: 'PHOTOS_PHOTO_NOTE_FONT_WEIGHT',
  PHOTOS_PHOTO_NOTE_FONT_NAME: 'PHOTOS_PHOTO_NOTE_FONT_NAME',
  PHOTOS_PHOTO_NOTE_FONT_SOURCE: 'PHOTOS_PHOTO_NOTE_FONT_SOURCE',
  PHOTOS_PHOTO_NOTE_FONT_ID: 'PHOTOS_PHOTO_NOTE_FONT_ID',

  PHOTOS_MOBILE_TITLE_SIZE: 'PHOTOS_MOBILE_TITLE_SIZE',
  PHOTOS_MOBILE_DESCRIPTION_SIZE: 'PHOTOS_MOBILE_DESCRIPTION_SIZE',
  PHOTOS_MOBILE_PHOTO_NOTE_SIZE: 'PHOTOS_MOBILE_PHOTO_NOTE_SIZE',

  IMAGE_DETAIL_TEXT_TYPE: 'IMAGE_DETAIL_TEXT_TYPE',
  IMAGE_DETAIL_BACKGROUND_COLOR: 'IMAGE_DETAIL_BACKGROUND_COLOR',
  IMAGE_DETAIL_TEXT_SIZE: 'IMAGE_DETAIL_TEXT_SIZE',
  IMAGE_DETAIL_TEXT_COLOR: 'IMAGE_DETAIL_TEXT_COLOR',
  IMAGE_DETAIL_TEXT_NAME: 'IMAGE_DETAIL_TEXT_NAME',
  IMAGE_DETAIL_TEXT_SOURCE: 'IMAGE_DETAIL_TEXT_SOURCE',
  IMAGE_DETAIL_TEXT_FONT_ID: 'IMAGE_DETAIL_TEXT_FONT_ID',
  IMAGE_DETAIL_TEXT_FONT_WEIGHT: 'IMAGE_DETAIL_TEXT_FONT_WEIGHT',

  IMAGE_DETAIL_MOBILE_TEXT_SIZE: 'IMAGE_DETAIL_MOBILE_TEXT_SIZE',

  PAGE_NAVIGATION_COLOR: 'PAGE_NAVIGATION_COLOR',
  PAGE_NAVIGATION_FONT_WEIGHT: 'PAGE_NAVIGATION_FONT_WEIGHT',
  PAGE_NAVIGATION_SIZE: 'PAGE_NAVIGATION_SIZE',
  PAGE_NAVIGATION_FONT_NAME: 'PAGE_NAVIGATION_FONT_NAME',
  PAGE_NAVIGATION_FONT_ID: 'PAGE_NAVIGATION_FONT_ID',
  PAGE_NAVIGATION_FONT_SOURCE: 'PAGE_NAVIGATION_FONT_SOURCE',
  TEXT_BLOCK_COLOR: 'TEXT_BLOCK_COLOR',
  TEXT_BLOCK_SIZE: 'TEXT_BLOCK_SIZE',
  TEXT_BLOCK_FONT_WEIGHT: 'TEXT_BLOCK_FONT_WEIGHT',
  TEXT_BLOCK_FONT_NAME: 'TEXT_BLOCK_FONT_NAME',
  TEXT_BLOCK_FONT_ID: 'TEXT_BLOCK_FONT_ID',
  TEXT_BLOCK_FONT_SOURCE: 'TEXT_BLOCK_FONT_SOURCE',
  BACKGROUND_COLOR: 'BACKGROUND_COLOR',
  HOVER_COLOR: 'HOVER_COLOR',
  BURGER_COLOR: 'BURGER_COLOR',
  LOADER_ANIMATION_COLOR: 'LOADER_ANIMATION_COLOR',
  LOADER_ANIMATION_VARIANT: 'LOADER_ANIMATION_VARIANT',
  LOADER_ANIMATION_CUSTOM: 'LOADER_ANIMATION_CUSTOM',
  LOADER_ANIMATION_CUSTOMMediaUrl: 'LOADER_ANIMATION_CUSTOMMediaUrl',
  SOCIAL_LINKS: 'SOCIAL_LINKS',
}

export const STYLE_TYPES = {
  logo: 'logo',
  logoMobile: 'logoMobile',
  header: 'header',
  headerMobile: 'headerMobile',
  burgerMenu: 'burgerMenu',
  footer: 'footer',
  footerMobile: 'footerMobile',
  burgerMenuMobile: 'burgerMenuMobile',
  navigation: 'navigation',
  album: 'album',
  albumMobile: 'albumMobile',
  albumList: 'albumList',
  albumListMobile: 'albumListMobile',
  photos: 'photos',
  photosMobile: 'photosMobile',
  slideshow: 'slideshow',
  contacts: 'contacts',
  text: 'text',
  burgerColor: 'burgerColor',
  hoverColor: 'hoverColor',
  pageBackground: 'pageBackground',
  loaderAnimation: 'loaderAnimation',
  socialLinks: 'socialLinks',
  photoMobile: 'photoMobile',
  imageDetail: 'imageDetail',
  imageDetailMobile: 'imageDetailMobile',
}
export const MESSAGE_TYPE = {
  UPDATE_PAGE: 'update_page',
  UPDATE_APOLLO_STATE: 'update_apollo_state',
  UPDATE_META: 'update_meta',
  UPDATE_FEATURE: 'update_feature',
  UPDATE_FEATURES: 'update_features',
  CHANGE_ROUTE: 'change_route',
  OPEN_EDIT_PAGE: 'open_edit_page',
  SET_LOGO_PREVIEW: 'set_logo_preview',
  SET_FAVICON_PREVIEW: 'set_favicon_preview',
  HOVER_ALBUM: 'hover_album',
  FRAME_LOADED: 'frame_loaded',
  CHECK_PATH: 'check_path',
  CURRENT_TEMPLATE_MARGINS: 'CURRENT_TEMPLATE_MARGINS',
  SET_FEATURE_ENABLED: 'set_feature_enabled',
}
export const UPDATE_APOLLO_TYPES = {
  album: 'album',
  page: 'page',
}

export const WEBSITE_NAVIGATION_VIEW_MODE = {
  MAIN: 'MAIN',
  ALBUM_ITEM_MOVING: 'ALBUM_ITEM_MOVING',
}

export const SCRIPT_TYPE = {
  GOOGLE_VERIFICATION_TAG: 'google-verification-tag',
}

export const MESSAGES_SPLIT_KEY = '6bfcdb609ac7'

export const FONT_WEIGHT_OPTIONS = {
  300: '300',
  400: '400',
  500: '500',
  600: '600',
  700: '700',
  800: '800',
  900: '900',
}

export const HEADER_MODE = {
  CLASSIC: 'classic',
  BURGER: 'burger',
}

export const HEADER_DESKTOP_TYPE = {
  CLASSIC: 'classic',
  BURGER: 'burger',
}

export const LOGO_TYPE = {
  TEXT: 'text',
  CUSTOM: 'custom',
}

export const HEADER_POSITION = {
  FIXED: false,
  SCROLLING: true,
}

export const BURGER_STYLE = {
  ICON: 'icon',
  TEXT: 'text',
}

export const BURGER_POSITION = {
  RIGHT: 'right',
  LEFT: 'left',
}

export const NAVIGATION_STYLE_ITEM_TYPE = {
  allCaps: 'uppercase',
  firstCaps: 'capitalize',
  lowerCase: 'lowercase',
  none: 'none',
}

export const LAYOUT_ALIGN_TYPE = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
}

export const FOOTER_ICON_TYPE = {
  CIRCLE: 'CIRCLE',
  SQUARE: 'SQUARE',
  ICON: 'ICON',
  SQUARE_ROUNDED: 'SQUARE_ROUNDED',
}

export const FOOTER_SOCIAL_ICON_COLOR = {
  BLACK: 'BLACK',
  WHITE: 'WHITE',
  COLORED: 'COLORED',
}

export const IMAGE_DETAIL_TEXT_TYPE = {
  TEXT: 'text',
  ICON: 'icon',
}
