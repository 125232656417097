import React from 'react'
import { injectIntl, intlShape } from 'react-intl'
import { Container, Header, Content, StyledToggle } from './nodes'
import { useSubscription } from '@configurator/providers/subscription'
import { useQuery, useMutation } from 'react-apollo'
import websiteMetaUpdateMutation from '@graphql/gql/website/websiteMetaUpdate.gql'
import isEmpty from 'lodash/isEmpty'
import websiteMetaGetQuery from '@graphql/gql/website/websiteMetaGet.gql'

const RemoveBranding = ({ intl: { formatMessage } }) => {
  const { permissions } = useSubscription()
  const { loading, data: { websiteMetaGet } = {} } = useQuery(websiteMetaGetQuery)
  const [websiteMetaUpdate, { error: metaUpdateError, loading: metaUpdateLoading }] =
    useMutation(websiteMetaUpdateMutation)

  if (loading || metaUpdateLoading) {
    return null
  }

  if (isEmpty(websiteMetaGet) || !isEmpty(websiteMetaGet.errors) || !isEmpty(metaUpdateError)) {
    return <div>Error</div>
  }
  const { data } = websiteMetaGet

  // toggle visibility
  const toggleVisibility = () => {
    // add loader
    websiteMetaUpdate({
      variables: {
        websiteId: data.id,
        hideLogo: !data.hideLogo,
      },
      refetchQueries: [
        {
          query: websiteMetaGetQuery,
          variables: {},
        },
      ],
    })
  }

  if (permissions.BRANDING_REMOVAL) {
    return (
      <Container>
        <Header>{formatMessage({ id: 'removeBranding.header' })}</Header>

        <Content>
          <span>{formatMessage({ id: 'removeBranding.text' })}</span>

          <StyledToggle
            data-testid='billing-page-remove-branding'
            checked={data.hideLogo}
            onChange={toggleVisibility}
          />
        </Content>
      </Container>
    )
  } else {
    return null
  }
}

RemoveBranding.propTypes = {
  intl: intlShape.isRequired,
}

RemoveBranding.defaultProps = {}

export default injectIntl(RemoveBranding)
