import {
  CustomLoaderPicker,
  Description,
  FormBlock,
  Header,
  SocialLinks,
  StylesColorPicker,
  StylesFontDropdowns,
  StylesFontSizeSlider,
  StylesLayoutAlignDropdown,
  StylesRadioGroup,
  StylesToggle,
  SubHeader,
  SubDescription,
  WrapperButton,
  WrapperHeader,
  WrapperImg,
} from '@configurator/components/pages/pageStyles/components'
import EditLogoContainer from '@configurator/containers/EditLogoContainer'
import { Button } from 'packages/components/buttons/button'
import Image from 'packages/components/image/Image'
import { Toggle } from 'packages/components/inputs'
import { MarginSlider } from 'packages/components/inputs/marginSlider/marginSlider'
import OpacityPicker from 'packages/components/inputs/opacityPicker/opacityPicker'
import {
  BURGER_POSITION,
  BURGER_STYLE,
  FEATURE_PROPERTY_KEYS,
  FOOTER_SOCIAL_ICON_COLOR,
  HEADER_DESKTOP_TYPE,
  HEADER_POSITION,
  IMAGE_DETAIL_TEXT_TYPE,
  LAYOUT_ALIGN_TYPE,
  LOGO_TYPE,
  NAVIGATION_STYLE_ITEM_TYPE,
  PORTFOLIO_TEMPLATES,
  STYLE_FEATURE_PROPERTIES,
} from 'packages/enum'
import { templateLogoMarginsSelector } from 'packages/selectors/Website'
import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { FormRow } from '../applyCodeModal/nodes'
import { StylesButtonsToggle } from './components/StylesButtonsToggle'
import { StylesFontWeight } from './components/stylesFontWeight'
import { SectionLabel, WrapperItem } from './nodes'

const getFontId = (font) => {
  const variants = (font.variants || []).join(',')
  const scripts = (font.scripts || []).join(',')
  return `${font.family}:${variants}:${scripts}`
}

const FormFontField = ({
  fontFieldName,
  fontIdFieldName,
  selectedStyle,
  values,
  initialValues,
  setFieldValue,
}) => {
  const pickerId = `${selectedStyle.type}_${fontFieldName}`
  const onChange = useCallback(
    (nextFont) => {
      if (nextFont.family !== initialValues[fontFieldName]) {
        setFieldValue(fontFieldName, nextFont.family)
        setFieldValue(fontIdFieldName, getFontId(nextFont))
      }
    },
    [fontFieldName, fontIdFieldName, initialValues, setFieldValue]
  )

  const fontFamilyProps = useMemo(
    () => ({
      name: fontFieldName,
      type: 'text',
      placeholder: 'fonts.update.placeholder.primary',
      activeFontFamily: values[fontFieldName],
      pickerId,
      onChange,
    }),
    [fontFieldName, onChange, pickerId, values]
  )
  return <StylesFontDropdowns fontFamilyProps={fontFamilyProps} />
}

const DefaultFieldsBlock = ({
  fontFieldName,
  fontIdFieldName,
  sizeFieldName,
  colorFieldName,
  selectedStyle,
  values,
  initialValues,
  setFieldValue,
  fontWeightFieldName,
  isLogo,
  isV2Template,
}) => {
  return (
    <FormBlock>
      {isLogo ? (
        <>
          <FormFontField
            fontFieldName={fontFieldName}
            fontIdFieldName={fontIdFieldName}
            selectedStyle={selectedStyle}
            values={values}
            initialValues={initialValues}
            setFieldValue={setFieldValue}
          />
          <StylesColorPicker name={colorFieldName} />
          <StylesFontSizeSlider name={sizeFieldName} />
          {isV2Template && <StylesFontWeight name={fontWeightFieldName} />}
        </>
      ) : (
        <>
          <FormFontField
            fontFieldName={fontFieldName}
            fontIdFieldName={fontIdFieldName}
            selectedStyle={selectedStyle}
            values={values}
            initialValues={initialValues}
            setFieldValue={setFieldValue}
          />
          <StylesFontSizeSlider name={sizeFieldName} />
          <StylesColorPicker name={colorFieldName} />
          {isV2Template && <StylesFontWeight name={fontWeightFieldName} />}
        </>
      )}
    </FormBlock>
  )
}

export const LogoFieldsBlock = ({ formatMessage, isV2Template, ...props }) => {
  const logoMargins = useSelector(templateLogoMarginsSelector)
  const [mode, setMode] = useState(props?.logoValues?.logo ? LOGO_TYPE.CUSTOM : LOGO_TYPE.TEXT)

  const modes = [
    {
      text: formatMessage({ id: 'styles.logo.mode.textLogo' }),
      type: LOGO_TYPE.TEXT,
    },
    {
      text: formatMessage({ id: 'styles.logo.mode.customLogo' }),
      type: LOGO_TYPE.CUSTOM,
    },
  ]

  return (
    <>
      <StylesButtonsToggle
        isLogoToggle
        value={mode}
        onChange={setMode}
        buttons={modes}
        {...props}
      />

      {mode === LOGO_TYPE.CUSTOM && (
        <EditLogoContainer disableInputs isV2Template={isV2Template} {...props} />
      )}

      {mode === LOGO_TYPE.TEXT ? (
        <>
          <EditLogoContainer
            disableUploadLogo
            textMode={LOGO_TYPE.TEXT}
            isV2Template={isV2Template}
            {...props}
          />

          <FormRow>
            <SubHeader>{formatMessage({ id: 'styles.title.logoTitle' })}</SubHeader>
          </FormRow>
          <DefaultFieldsBlock
            fontFieldName={STYLE_FEATURE_PROPERTIES.HEADER_LOGO_FONT_NAME}
            fontIdFieldName={STYLE_FEATURE_PROPERTIES.HEADER_LOGO_FONT_ID}
            sizeFieldName={STYLE_FEATURE_PROPERTIES.HEADER_LOGO_SIZE}
            colorFieldName={STYLE_FEATURE_PROPERTIES.HEADER_LOGO_COLOR}
            fontWeightFieldName={STYLE_FEATURE_PROPERTIES.HEADER_LOGO_FONT_WEIGHT}
            isV2Template={isV2Template}
            isLogo
            {...props}
          />
          <div>
            {isV2Template && (
              <MarginSlider
                name={STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_LOGO_MARGIN_TOP}
                label={formatMessage({ id: 'styles.logo.paddingTop' })}
                min={1}
                max={3}
                step={0.2}
                style={{ marginBottom: '25px' }}
                initialMargin={logoMargins.top}
                {...props}
              />
            )}
            {isV2Template && (
              <MarginSlider
                name={STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_LOGO_MARGIN_BOTTOM}
                label={formatMessage({ id: 'styles.logo.paddingBottom' })}
                min={1}
                max={3}
                step={0.2}
                initialMargin={logoMargins.bottom}
                {...props}
              />
            )}
            {!isV2Template && (
              <MarginSlider
                name={STYLE_FEATURE_PROPERTIES.HEADER_LOGO_MARGIN_TOP}
                label={formatMessage({ id: 'styles.logo.paddingTop' })}
                min={1}
                max={3}
                step={0.2}
                style={{ marginBottom: '25px' }}
                initialMargin={logoMargins.top}
                {...props}
              />
            )}
            {!isV2Template && (
              <MarginSlider
                name={STYLE_FEATURE_PROPERTIES.HEADER_LOGO_MARGIN_BOTTOM}
                label={formatMessage({ id: 'styles.logo.paddingBottom' })}
                min={1}
                max={3}
                step={0.2}
                initialMargin={logoMargins.bottom}
                {...props}
              />
            )}
          </div>
          <SubHeader>{formatMessage({ id: 'styles.title.header.sublogo' })}</SubHeader>
          <DefaultFieldsBlock
            fontFieldName={STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_FONT_NAME}
            fontIdFieldName={STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_FONT_ID}
            sizeFieldName={STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_SIZE}
            colorFieldName={STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_COLOR}
            fontWeightFieldName={STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_FONT_WEIGHT}
            isV2Template={isV2Template}
            isLogo
            {...props}
          />
          {isV2Template && (
            <MarginSlider
              name={STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_MARGIN_TOP}
              label={formatMessage({ id: 'styles.logo.paddingTop' })}
              min={1}
              max={3}
              step={0.2}
              initialMargin={logoMargins.bottom}
              style={{ marginBottom: '25px' }}
              {...props}
            />
          )}
          {isV2Template && (
            <MarginSlider
              name={STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_MARGIN_BOTTOM}
              label={formatMessage({ id: 'styles.logo.paddingBottom' })}
              min={1}
              max={3}
              step={0.2}
              initialMargin={logoMargins.bottom}
              {...props}
            />
          )}
        </>
      ) : (
        <>
          <SubHeader>{formatMessage({ id: 'styles.title.logoTitle' })}</SubHeader>
          <div>
            {isV2Template && (
              <MarginSlider
                name={STYLE_FEATURE_PROPERTIES.HEADER_CUSTOM_LOGO_TOP_PADDING}
                label={formatMessage({ id: 'styles.logo.paddingTop' })}
                min={1}
                max={3}
                step={0.2}
                style={{ marginBottom: '20px' }}
                initialMargin={logoMargins.top}
                {...props}
              />
            )}
            {isV2Template && (
              <MarginSlider
                name={STYLE_FEATURE_PROPERTIES.HEADER_CUSTOM_LOGO_BOTTOM_PADDING}
                label={formatMessage({ id: 'styles.logo.paddingBottom' })}
                min={1}
                max={3}
                step={0.2}
                initialMargin={logoMargins.bottom}
                {...props}
              />
            )}
            {!isV2Template && (
              <MarginSlider
                name={STYLE_FEATURE_PROPERTIES.HEADER_LOGO_MARGIN_TOP}
                label={formatMessage({ id: 'styles.logo.paddingTop' })}
                min={1}
                max={3}
                step={0.2}
                style={{ marginBottom: '25px' }}
                initialMargin={logoMargins.top}
                {...props}
              />
            )}
            {!isV2Template && (
              <MarginSlider
                name={STYLE_FEATURE_PROPERTIES.HEADER_LOGO_MARGIN_BOTTOM}
                label={formatMessage({ id: 'styles.logo.paddingBottom' })}
                min={1}
                max={3}
                step={0.2}
                initialMargin={logoMargins.bottom}
                {...props}
              />
            )}
          </div>
        </>
      )}
    </>
  )
}

export const LogoMobileFieldsBlock = ({ formatMessage, isV2Template, ...props }) => {
  const logoMargins = useSelector(templateLogoMarginsSelector)

  const mode = props?.logoValues?.logo ? LOGO_TYPE.CUSTOM : LOGO_TYPE.TEXT

  return (
    <>
      <SubHeader>{formatMessage({ id: 'styles.title.logoTitle' })}</SubHeader>

      {mode === LOGO_TYPE.TEXT ? (
        <>
          <WrapperItem>
            <StylesFontSizeSlider name={STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_LOGO_SIZE} />
          </WrapperItem>
          <WrapperItem>
            <MarginSlider
              name={STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_LOGO_MARGIN_TOP}
              label={formatMessage({ id: 'styles.logo.paddingTop' })}
              min={1}
              max={3}
              step={0.2}
              initialMargin={logoMargins.top}
              {...props}
            />
          </WrapperItem>
          <WrapperItem>
            <MarginSlider
              name={STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_LOGO_MARGIN_BOTTOM}
              label={formatMessage({ id: 'styles.logo.paddingBottom' })}
              min={1}
              max={3}
              step={0.2}
              initialMargin={logoMargins.top}
              {...props}
            />
          </WrapperItem>
          <SubHeader>{formatMessage({ id: 'styles.title.header.sublogo' })}</SubHeader>
          <WrapperItem>
            <StylesFontSizeSlider name={STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_SUBLOGO_SIZE} />
          </WrapperItem>
          <WrapperItem>
            <MarginSlider
              name={STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_SUBLOGO_MARGIN_TOP}
              label={formatMessage({ id: 'styles.logo.paddingTop' })}
              min={1}
              max={3}
              step={0.2}
              initialMargin={logoMargins.top}
              {...props}
            />
          </WrapperItem>
          <WrapperItem>
            <MarginSlider
              name={STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_SUBLOGO_MARGIN_BOTTOM}
              label={formatMessage({ id: 'styles.logo.paddingBottom' })}
              min={1}
              max={3}
              step={0.2}
              initialMargin={logoMargins.top}
              {...props}
            />
          </WrapperItem>
        </>
      ) : (
        <>
          <EditLogoContainer isMobile disableInputs isV2Template={isV2Template} {...props} />
          <WrapperItem>
            <MarginSlider
              name={STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_CUSTOM_LOGO_TOP_PADDING}
              label={formatMessage({ id: 'styles.logo.paddingTop' })}
              min={1}
              max={3}
              step={0.2}
              initialMargin={logoMargins.top}
              {...props}
            />
          </WrapperItem>
          <WrapperItem>
            <MarginSlider
              name={STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_CUSTOM_LOGO_BOTTOM_PADDING}
              label={formatMessage({ id: 'styles.logo.paddingBottom' })}
              min={1}
              max={3}
              step={0.2}
              initialMargin={logoMargins.top}
              {...props}
            />
          </WrapperItem>
        </>
      )}
    </>
  )
}

const navigationStyleItems = [
  {
    value: NAVIGATION_STYLE_ITEM_TYPE.allCaps,
    label: 'styles.footer.defaultFooterLinks.allCaps',
  },
  {
    value: NAVIGATION_STYLE_ITEM_TYPE.firstCaps,
    label: 'styles.footer.defaultFooterLinks.firstCaps',
  },
  {
    value: NAVIGATION_STYLE_ITEM_TYPE.lowerCase,
    label: 'styles.footer.defaultFooterLinks.lowerCase',
  },
]

const headerNavigationStyleItems = [
  {
    value: NAVIGATION_STYLE_ITEM_TYPE.none,
    label: 'styles.header.navigationLinks.initial',
  },
  {
    value: NAVIGATION_STYLE_ITEM_TYPE.allCaps,
    label: 'styles.header.navigationLinks.allCaps',
  },
  {
    value: NAVIGATION_STYLE_ITEM_TYPE.firstCaps,
    label: 'styles.header.navigationLinks.firstCaps',
  },
  {
    value: NAVIGATION_STYLE_ITEM_TYPE.lowerCase,
    label: 'styles.header.navigationLinks.lowerCase',
  },
]

export const HeaderFieldsBlock = ({
  formatMessage,
  scrollableHeader,
  toggleScollableHeader,
  isV2Template,
  templateName,
  ...props
}) => {
  const logoMargins = useSelector(templateLogoMarginsSelector)

  const [mode, setMode] = useState(props?.initialValues?.HEADER_DESKTOP_TYPE)
  const [burgerStyle, setBurgerStyle] = useState(props?.initialValues?.HEADER_DESKTOP_BURGER_STYLE)

  const layoutAlignOptions = [
    {
      text: formatMessage({ id: 'styles.header.layoutAlign.left' }),
      value: LAYOUT_ALIGN_TYPE.LEFT,
    },
    {
      text: formatMessage({ id: 'styles.header.layoutAlign.right' }),
      value: LAYOUT_ALIGN_TYPE.RIGHT,
    },
    {
      text: formatMessage({ id: 'styles.header.layoutAlign.center' }),
      value: LAYOUT_ALIGN_TYPE.CENTER,
    },
  ]

  const modes = [
    {
      text: formatMessage({ id: 'styles.header.mode.classic' }),
      type: HEADER_DESKTOP_TYPE.CLASSIC,
    },
    {
      text: formatMessage({ id: 'styles.header.mode.burger' }),
      type: HEADER_DESKTOP_TYPE.BURGER,
    },
  ]

  const burgerStyles = [
    {
      text: formatMessage({ id: 'styles.header.mode.icon' }),
      type: BURGER_STYLE.ICON,
    },
    {
      text: formatMessage({ id: 'styles.header.mode.text' }),
      type: BURGER_STYLE.TEXT,
    },
  ]

  const burgerPosition = [
    {
      text: formatMessage({ id: 'styles.header.layoutAlign.right' }),
      value: BURGER_POSITION.RIGHT,
    },
    {
      text: formatMessage({ id: 'styles.header.layoutAlign.left' }),
      value: BURGER_POSITION.LEFT,
    },
  ]

  const headerPosition = [
    {
      value: HEADER_POSITION.FIXED,
      label: 'styles.header.position.fixed',
    },
    {
      value: HEADER_POSITION.SCROLLING,
      label: 'styles.header.position.scrolling',
    },
  ]

  return (
    <>
      {!!scrollableHeader && templateName !== PORTFOLIO_TEMPLATES.MADRID && (
        <StylesRadioGroup
          label='styles.header.position'
          name={FEATURE_PROPERTY_KEYS.SCROLLABLE_HEADER}
          items={headerPosition}
          toggleHandler={toggleScollableHeader}
        />
      )}
      {isV2Template && (
        <StylesButtonsToggle
          value={mode}
          onChange={setMode}
          buttons={modes}
          fieldName={STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_TYPE}
          {...props}
        />
      )}
      {isV2Template && (
        <WrapperItem>
          <StylesLayoutAlignDropdown
            options={layoutAlignOptions}
            label='styles.header.layoutAlign'
            name={STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_LAYOUT_ALIGN}
          />
        </WrapperItem>
      )}
      {isV2Template && (
        <MarginSlider
          name={STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_PADDING_TOP}
          label={formatMessage({ id: 'styles.header.paddingTop' })}
          min={1}
          max={3}
          step={0.2}
          style={{ marginBottom: '20px' }}
          initialMargin={logoMargins.top}
          {...props}
        />
      )}
      {isV2Template && (
        <MarginSlider
          name={STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_PADDING_BOTTOM}
          label={formatMessage({ id: 'styles.header.paddingBottom' })}
          min={1}
          max={3}
          step={0.2}
          style={{ marginBottom: '20px' }}
          initialMargin={logoMargins.bottom}
          {...props}
        />
      )}
      {isV2Template && (
        <>
          <SubHeader>
            {formatMessage({
              id: 'styles.header.headerColor',
            })}
          </SubHeader>
          <SubDescription>
            {formatMessage({
              id: 'styles.header.headerColor.desc',
            })}
          </SubDescription>
        </>
      )}
      {isV2Template && (
        <WrapperItem>
          <StylesColorPicker
            name={STYLE_FEATURE_PROPERTIES.HEADER_BACKGROUND_COLOR}
            customText=' '
          />
        </WrapperItem>
      )}
      {isV2Template && (
        <>
          <SubDescription>
            {formatMessage({
              id: 'styles.header.headerColor.opacity',
            })}
          </SubDescription>
          <OpacityPicker
            name={STYLE_FEATURE_PROPERTIES.HEADER_BACKGROUND_OPACITY}
            min={0}
            max={1}
            step={0.1}
            {...props}
          />
        </>
      )}
      {!isV2Template && (
        <SubHeader>
          {formatMessage({
            id: 'styles.header.navigationLinks',
          })}
        </SubHeader>
      )}
      {!isV2Template && (
        <DefaultFieldsBlock
          fontFieldName={STYLE_FEATURE_PROPERTIES.HEADER_LINKS_FONT_NAME}
          fontIdFieldName={STYLE_FEATURE_PROPERTIES.HEADER_LINKS_FONT_ID}
          sizeFieldName={STYLE_FEATURE_PROPERTIES.HEADER_LINKS_SIZE}
          colorFieldName={STYLE_FEATURE_PROPERTIES.HEADER_LINKS_COLOR}
          fontWeightFieldName={STYLE_FEATURE_PROPERTIES.HEADER_LINKS_FONT_WEIGHT}
          isV2Template={isV2Template}
          {...props}
        />
      )}

      {mode === HEADER_DESKTOP_TYPE.CLASSIC && (
        <>
          {isV2Template && (
            <StylesRadioGroup
              name={STYLE_FEATURE_PROPERTIES.HEADER_LINKS_STYLE}
              label='styles.header.navigationLinks'
              items={headerNavigationStyleItems}
            />
          )}
          <DefaultFieldsBlock
            fontFieldName={STYLE_FEATURE_PROPERTIES.HEADER_LINKS_FONT_NAME}
            fontIdFieldName={STYLE_FEATURE_PROPERTIES.HEADER_LINKS_FONT_ID}
            sizeFieldName={STYLE_FEATURE_PROPERTIES.HEADER_LINKS_SIZE}
            colorFieldName={STYLE_FEATURE_PROPERTIES.HEADER_LINKS_COLOR}
            fontWeightFieldName={STYLE_FEATURE_PROPERTIES.HEADER_LINKS_FONT_WEIGHT}
            isV2Template={isV2Template}
            {...props}
          />
          {isV2Template && (
            <MarginSlider
              name={STYLE_FEATURE_PROPERTIES.HEADER_LINKS_PADDING_TOP}
              label={formatMessage({ id: 'styles.header.paddingTop' })}
              min={1}
              max={3}
              step={0.2}
              style={{ marginBottom: '20px' }}
              initialMargin={logoMargins.top}
              {...props}
            />
          )}
          {isV2Template && (
            <MarginSlider
              name={STYLE_FEATURE_PROPERTIES.HEADER_LINKS_PADDING_BOTTOM}
              label={formatMessage({ id: 'styles.header.paddingBottom' })}
              min={1}
              max={3}
              step={0.2}
              style={{ marginBottom: '20px' }}
              initialMargin={logoMargins.bottom}
              {...props}
            />
          )}
        </>
      )}

      {mode === HEADER_DESKTOP_TYPE.BURGER && (
        <>
          <StylesButtonsToggle
            label='styles.header.burger.style'
            value={burgerStyle}
            onChange={setBurgerStyle}
            buttons={burgerStyles}
            fieldName={STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_BURGER_STYLE}
            {...props}
          />
          {burgerStyle === BURGER_STYLE.TEXT && (
            <WrapperItem>
              <DefaultFieldsBlock
                fontFieldName={STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_BURGER_TEXT_NAME}
                fontIdFieldName={STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_BURGER_TEXT_ID}
                sizeFieldName={STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_BURGER_TEXT_SIZE}
                colorFieldName={STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_BURGER_TEXT_COLOR}
                fontWeightFieldName={STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_BURGER_TEXT_WEIGHT}
                isV2Template={isV2Template}
                {...props}
              />
            </WrapperItem>
          )}
          {burgerStyle === BURGER_STYLE.ICON && (
            <WrapperItem>
              <StylesColorPicker name={STYLE_FEATURE_PROPERTIES.BURGER_COLOR} />
            </WrapperItem>
          )}

          {props?.values[STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_LAYOUT_ALIGN] ===
          LAYOUT_ALIGN_TYPE.CENTER ? (
            <WrapperItem>
              <StylesLayoutAlignDropdown
                options={burgerPosition}
                label='styles.header.burger.position'
                name={STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_BURGER_POSITION}
              />
            </WrapperItem>
          ) : null}
        </>
      )}
    </>
  )
}

export const BurgerMenuFieldsBlock = ({
  formatMessage,
  scrollableHeader,
  toggleScollableHeader,
  ...props
}) => {
  const logoMargins = useSelector(templateLogoMarginsSelector)

  const layoutAlignOptions = [
    {
      text: formatMessage({ id: 'styles.header.layoutAlign.left' }),
      value: LAYOUT_ALIGN_TYPE.LEFT,
    },
    {
      text: formatMessage({ id: 'styles.header.layoutAlign.right' }),
      value: LAYOUT_ALIGN_TYPE.RIGHT,
    },
    {
      text: formatMessage({ id: 'styles.header.layoutAlign.center' }),
      value: LAYOUT_ALIGN_TYPE.CENTER,
    },
  ]

  const iconsColor = [
    {
      text: formatMessage({ id: 'styles.socialLinks.iconColor.black' }),
      value: FOOTER_SOCIAL_ICON_COLOR.BLACK,
    },
    {
      text: formatMessage({ id: 'styles.socialLinks.iconColor.white' }),
      value: FOOTER_SOCIAL_ICON_COLOR.WHITE,
    },
    {
      text: formatMessage({ id: 'styles.socialLinks.iconColor.colored' }),
      value: FOOTER_SOCIAL_ICON_COLOR.COLORED,
    },
  ]

  return (
    <>
      <WrapperItem>
        <StylesColorPicker
          name={STYLE_FEATURE_PROPERTIES.BURGER_BACKGROUND_COLOR}
          customText='backgroundColor'
        />
        <StylesColorPicker
          name={STYLE_FEATURE_PROPERTIES.BURGER_CLOSE_ICON_COLOR}
          customText='closeIconColor'
        />
      </WrapperItem>
      <WrapperItem>
        <StylesRadioGroup
          name={STYLE_FEATURE_PROPERTIES.BURGER_NAVIGATION_LINKS_STYLE}
          label='styles.burgerMenu.navigationLinks'
          items={headerNavigationStyleItems}
        />
      </WrapperItem>
      <WrapperItem>
        <StylesLayoutAlignDropdown
          options={layoutAlignOptions}
          label='styles.burgerMenu.layoutAlign'
          name={STYLE_FEATURE_PROPERTIES.BURGER_DESKTOP_NAVIGATION_LAYOUT_ALIGN}
        />
      </WrapperItem>
      <DefaultFieldsBlock
        fontFieldName={STYLE_FEATURE_PROPERTIES.BURGER_NAVIGATION_LINKS_FONT_NAME}
        fontIdFieldName={STYLE_FEATURE_PROPERTIES.BURGER_NAVIGATION_LINKS_FONT_ID}
        sizeFieldName={STYLE_FEATURE_PROPERTIES.BURGER_DESKTOP_NAVIGATION_LINKS_FONT_SIZE}
        colorFieldName={STYLE_FEATURE_PROPERTIES.BURGER_NAVIGATION_LINKS_COLOR}
        fontWeightFieldName={STYLE_FEATURE_PROPERTIES.BURGER_NAVIGATION_LINKS_FONT_WEIGHT}
        {...props}
      />
      <MarginSlider
        name={STYLE_FEATURE_PROPERTIES.BURGER_DESKTOP_NAVIGATION_LINKS_TOP_PADDING}
        label={formatMessage({ id: 'styles.header.paddingTop' })}
        min={1}
        max={3}
        step={0.2}
        style={{ marginBottom: '20px' }}
        initialMargin={logoMargins.top}
        {...props}
      />
      <MarginSlider
        name={STYLE_FEATURE_PROPERTIES.BURGER_DESKTOP_NAVIGATION_LINKS_BOTTOM_PADDING}
        label={formatMessage({ id: 'styles.header.paddingBottom' })}
        min={1}
        max={3}
        step={0.2}
        style={{ marginBottom: '20px' }}
        initialMargin={logoMargins.bottom}
        {...props}
      />
      <WrapperItem>
        <SectionLabel>{formatMessage({ id: 'styles.section.label.logo' })}</SectionLabel>
        <StylesColorPicker
          name={STYLE_FEATURE_PROPERTIES.BURGER_LOGO_COLOR}
          customText='logoColor'
        />
        <StylesColorPicker
          name={STYLE_FEATURE_PROPERTIES.BURGER_SUBLOGO_COLOR}
          customText='sublogoColor'
        />
      </WrapperItem>
      <WrapperItem>
        <SectionLabel>{formatMessage({ id: 'styles.title.footer' })}</SectionLabel>
        <StylesColorPicker
          name={STYLE_FEATURE_PROPERTIES.BURGER_FOOTER_COLOR}
          customText='styles.burgerMenu.navigation.links.color'
        />
        <StylesLayoutAlignDropdown
          options={iconsColor}
          label='styles.socialLinks.iconColor'
          name={STYLE_FEATURE_PROPERTIES.BURGER_FOOTER_SOCIAL_ICON_COLOR}
        />
      </WrapperItem>
    </>
  )
}

export const BurgerMenuMobileFieldsBlock = ({
  formatMessage,
  scrollableHeader,
  toggleScollableHeader,
  ...props
}) => {
  const logoMargins = useSelector(templateLogoMarginsSelector)

  const layoutAlignOptions = [
    {
      text: formatMessage({ id: 'styles.header.layoutAlign.left' }),
      value: LAYOUT_ALIGN_TYPE.LEFT,
    },
    {
      text: formatMessage({ id: 'styles.header.layoutAlign.right' }),
      value: LAYOUT_ALIGN_TYPE.RIGHT,
    },
    {
      text: formatMessage({ id: 'styles.header.layoutAlign.center' }),
      value: LAYOUT_ALIGN_TYPE.CENTER,
    },
  ]

  const iconsColor = [
    {
      text: formatMessage({ id: 'styles.socialLinks.iconColor.black' }),
      value: FOOTER_SOCIAL_ICON_COLOR.BLACK,
    },
    {
      text: formatMessage({ id: 'styles.socialLinks.iconColor.white' }),
      value: FOOTER_SOCIAL_ICON_COLOR.WHITE,
    },
    {
      text: formatMessage({ id: 'styles.socialLinks.iconColor.colored' }),
      value: FOOTER_SOCIAL_ICON_COLOR.COLORED,
    },
  ]

  return (
    <>
      <WrapperItem>
        <StylesColorPicker
          name={STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_BACKGROUND_COLOR}
          customText='backgroundColor'
        />
        <StylesColorPicker
          name={STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_CLOSE_ICON_COLOR}
          customText='closeIconColor'
        />
      </WrapperItem>
      <WrapperItem>
        <StylesRadioGroup
          name={STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_NAVIGATION_LINKS_STYLE}
          label='styles.burgerMenu.navigationLinks'
          items={headerNavigationStyleItems}
        />
      </WrapperItem>

      <WrapperItem>
        <StylesLayoutAlignDropdown
          options={layoutAlignOptions}
          label='styles.burgerMenu.layoutAlign'
          name={STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_NAVIGATION_LAYOUT_ALIGN}
        />
      </WrapperItem>
      <DefaultFieldsBlock
        fontFieldName={STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_NAVIGATION_LINKS_FONT_NAME}
        fontIdFieldName={STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_NAVIGATION_LINKS_FONT_ID}
        sizeFieldName={STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_NAVIGATION_LINKS_FONT_SIZE}
        colorFieldName={STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_NAVIGATION_LINKS_COLOR}
        fontWeightFieldName={STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_NAVIGATION_LINKS_FONT_WEIGHT}
        {...props}
      />
      <MarginSlider
        name={STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_NAVIGATION_LINKS_TOP_PADDING}
        label={formatMessage({ id: 'styles.header.paddingTop' })}
        min={1}
        max={3}
        step={0.2}
        style={{ marginBottom: '20px' }}
        initialMargin={logoMargins.top}
        {...props}
      />
      <MarginSlider
        name={STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_NAVIGATION_LINKS_BOTTOM_PADDING}
        label={formatMessage({ id: 'styles.header.paddingBottom' })}
        min={1}
        max={3}
        step={0.2}
        style={{ marginBottom: '20px' }}
        initialMargin={logoMargins.bottom}
        {...props}
      />
      <WrapperItem>
        <SectionLabel>{formatMessage({ id: 'styles.section.label.logo' })}</SectionLabel>
        <StylesColorPicker
          name={STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_LOGO_COLOR}
          customText='logoColor'
        />
        <StylesColorPicker
          name={STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_SUBLOGO_COLOR}
          customText='sublogoColor'
        />
      </WrapperItem>
      <WrapperItem>
        <SectionLabel>{formatMessage({ id: 'styles.title.footer' })}</SectionLabel>
        <StylesColorPicker
          name={STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_FOOTER_COLOR}
          customText='styles.burgerMenu.navigation.links.color'
        />
        <StylesLayoutAlignDropdown
          options={iconsColor}
          label='styles.socialLinks.iconColor'
          name={STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_FOOTER_SOCIAL_ICON_COLOR}
        />
      </WrapperItem>
    </>
  )
}

export const HeaderMobileFieldsBlock = ({
  formatMessage,
  scrollableHeader,
  toggleScollableHeader,
  ...props
}) => {
  const logoMargins = useSelector(templateLogoMarginsSelector)
  const [burgerStyle, setBurgerStyle] = useState(props?.initialValues?.HEADER_MOBILE_BURGER_STYLE)

  const layoutAlignOptions = [
    {
      text: formatMessage({ id: 'styles.header.layoutAlign.left' }),
      value: LAYOUT_ALIGN_TYPE.LEFT,
    },
    {
      text: formatMessage({ id: 'styles.header.layoutAlign.right' }),
      value: LAYOUT_ALIGN_TYPE.RIGHT,
    },
  ]

  const burgerStyles = [
    {
      text: formatMessage({ id: 'styles.header.mode.icon' }),
      type: BURGER_STYLE.ICON,
    },
    {
      text: formatMessage({ id: 'styles.header.mode.text' }),
      type: BURGER_STYLE.TEXT,
    },
  ]

  return (
    <>
      <WrapperItem>
        <StylesLayoutAlignDropdown
          options={layoutAlignOptions}
          label='styles.header.layoutAlign'
          name={STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_LAYOUT_ALIGN}
        />
      </WrapperItem>
      <MarginSlider
        name={STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_PADDING_TOP}
        label={formatMessage({ id: 'styles.logo.paddingTop' })}
        min={1}
        max={3}
        step={0.2}
        style={{ marginBottom: '30px' }}
        initialMargin={logoMargins.top}
        {...props}
      />
      <MarginSlider
        name={STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_PADDING_BOTTOM}
        label={formatMessage({ id: 'styles.logo.paddingBottom' })}
        min={1}
        max={3}
        step={0.2}
        style={{ marginBottom: '30px' }}
        initialMargin={logoMargins.top}
        {...props}
      />

      <SubHeader>
        {formatMessage({
          id: 'styles.header.headerColor',
        })}
      </SubHeader>
      <SubDescription>
        {formatMessage({
          id: 'styles.header.headerColor.desc',
        })}
      </SubDescription>

      <WrapperItem>
        <StylesColorPicker name={STYLE_FEATURE_PROPERTIES.HEADER_BACKGROUND_COLOR} customText=' ' />
      </WrapperItem>

      <OpacityPicker
        name={STYLE_FEATURE_PROPERTIES.HEADER_BACKGROUND_OPACITY}
        min={0}
        max={1}
        step={0.1}
        {...props}
      />

      <StylesButtonsToggle
        label='styles.header.burger.style'
        value={burgerStyle}
        onChange={setBurgerStyle}
        buttons={burgerStyles}
        fieldName={STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_BURGER_STYLE}
        {...props}
      />

      {burgerStyle === BURGER_STYLE.TEXT && (
        <>
          <WrapperItem>
            <DefaultFieldsBlock
              fontFieldName={STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_BURGER_TEXT_NAME}
              fontIdFieldName={STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_BURGER_TEXT_ID}
              sizeFieldName={STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_BURGER_TEXT_SIZE}
              colorFieldName={STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_BURGER_TEXT_COLOR}
              fontWeightFieldName={STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_BURGER_TEXT_WEIGHT}
              {...props}
            />
          </WrapperItem>
        </>
      )}
      {burgerStyle === BURGER_STYLE.ICON && (
        <>
          <WrapperItem>
            <StylesColorPicker name={STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_BURGER_ICON_COLOR} />
          </WrapperItem>
        </>
      )}
    </>
  )
}

export const NavigationFieldsBlock = ({ formatMessage, isV2Template, ...props }) => {
  return (
    <>
      {isV2Template && (
        <SubDescription>
          {formatMessage({
            id: 'styles.title.mainNavigation.navigation.description',
          })}
        </SubDescription>
      )}
      <DefaultFieldsBlock
        fontFieldName={STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_FONT_NAME}
        fontIdFieldName={STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_FONT_ID}
        sizeFieldName={STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_SIZE}
        colorFieldName={STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_COLOR}
        fontWeightFieldName={STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_FONT_WEIGHT}
        isV2Template={isV2Template}
        {...props}
      />
    </>
  )
}

export const FooterFieldsBlock = ({ formatMessage, isV2Template, ...props }) => {
  const layoutAlignOptions = [
    {
      text: formatMessage({ id: 'styles.footer.layoutAlign.left' }),
      value: LAYOUT_ALIGN_TYPE.LEFT,
    },
    {
      text: formatMessage({ id: 'styles.footer.layoutAlign.right' }),
      value: LAYOUT_ALIGN_TYPE.RIGHT,
    },
    {
      text: formatMessage({ id: 'styles.footer.layoutAlign.center' }),
      value: LAYOUT_ALIGN_TYPE.CENTER,
    },
  ]

  return (
    <>
      <StylesLayoutAlignDropdown
        options={layoutAlignOptions}
        label='styles.footer.layoutAlign'
        name={STYLE_FEATURE_PROPERTIES.FOOTER_LAYOUT_ALIGN}
      />
      {isV2Template && (
        <WrapperItem>
          <StylesColorPicker
            name={STYLE_FEATURE_PROPERTIES.FOOTER_BACKGROUND_COLOR}
            customText='backgroundColor'
          />
        </WrapperItem>
      )}
      {isV2Template && (
        <WrapperItem>
          <OpacityPicker
            name={STYLE_FEATURE_PROPERTIES.FOOTER_BACKGROUND_OPACITY}
            min={0}
            max={1}
            step={0.1}
            {...props}
          />
        </WrapperItem>
      )}
      <StylesRadioGroup
        name={STYLE_FEATURE_PROPERTIES.FOOTER_LINK_STYLE}
        label='styles.footer.defaultFooterLinks'
        items={navigationStyleItems}
      />
      <DefaultFieldsBlock
        fontFieldName={STYLE_FEATURE_PROPERTIES.FOOTER_FONT_NAME}
        fontIdFieldName={STYLE_FEATURE_PROPERTIES.FOOTER_FONT_ID}
        sizeFieldName={STYLE_FEATURE_PROPERTIES.FOOTER_SIZE}
        colorFieldName={STYLE_FEATURE_PROPERTIES.FOOTER_COLOR}
        fontWeightFieldName={STYLE_FEATURE_PROPERTIES.FOOTER_FONT_WEIGHT}
        isV2Template={isV2Template}
        {...props}
      />
      <StylesToggle
        name={STYLE_FEATURE_PROPERTIES.FOOTER_SHOW_YEAR}
        label='styles.footer.toggle.showYear'
        {...props}
      />
      <StylesToggle
        name={STYLE_FEATURE_PROPERTIES.FOOTER_SHOW_DOMAIN_TITLE}
        label='styles.footer.toggle.showDomainTitle'
        {...props}
      />
    </>
  )
}

export const AlbumFieldsBlock = ({ formatMessage, isV2Template, ...props }) => (
  <>
    <SubHeader>{formatMessage({ id: 'styles.title.albumPage.title' })}</SubHeader>
    <DefaultFieldsBlock
      fontFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_FONT_NAME}
      fontIdFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_FONT_ID}
      sizeFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_SIZE}
      colorFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_COLOR}
      fontWeightFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_FONT_WEIGHT}
      isV2Template={isV2Template}
      {...props}
    />
    <SubHeader>{formatMessage({ id: 'styles.title.albumPage.description' })}</SubHeader>
    <DefaultFieldsBlock
      fontFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_FONT_NAME}
      fontIdFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_FONT_ID}
      sizeFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_SIZE}
      colorFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_COLOR}
      fontWeightFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_FONT_WEIGHT}
      isV2Template={isV2Template}
      {...props}
    />
    <SubHeader>{formatMessage({ id: 'styles.title.albumPage.note' })}</SubHeader>
    <DefaultFieldsBlock
      fontFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_FONT_NAME}
      fontIdFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_FONT_ID}
      sizeFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_SIZE}
      colorFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_COLOR}
      fontWeightFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_FONT_WEIGHT}
      isV2Template={isV2Template}
      {...props}
    />
  </>
)

export const AlbumMobileFieldsBlock = ({ formatMessage }) => (
  <>
    <SubHeader>{formatMessage({ id: 'styles.title.albumPage.title' })}</SubHeader>
    <StylesFontSizeSlider name={STYLE_FEATURE_PROPERTIES.ALBUM_MOBILE_TITLE_SIZE} />
    <SubHeader>{formatMessage({ id: 'styles.title.albumPage.description' })}</SubHeader>
    <StylesFontSizeSlider name={STYLE_FEATURE_PROPERTIES.ALBUM_MOBILE_DESCRIPTION_SIZE} />
    <SubHeader>{formatMessage({ id: 'styles.title.albumPage.imgDescription' })}</SubHeader>
    <StylesFontSizeSlider name={STYLE_FEATURE_PROPERTIES.ALBUM_MOBILE_PHOTO_NOTE_SIZE} />
  </>
)

export const AlbumListFieldsBlock = ({ formatMessage, isV2Template, ...props }) => (
  <>
    <SubHeader>{formatMessage({ id: 'styles.title.albumPage.title' })}</SubHeader>
    <DefaultFieldsBlock
      fontFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_LIST_TITLE_FONT_NAME}
      fontIdFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_LIST_TITLE_FONT_ID}
      sizeFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_LIST_TITLE_SIZE}
      colorFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_LIST_TITLE_COLOR}
      fontWeightFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_LIST_TITLE_FONT_WEIGHT}
      isV2Template={isV2Template}
      {...props}
    />
    <SubHeader>{formatMessage({ id: 'styles.title.albumPage.description' })}</SubHeader>
    <DefaultFieldsBlock
      fontFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_LIST_DESCRIPTION_FONT_NAME}
      fontIdFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_LIST_DESCRIPTION_FONT_ID}
      sizeFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_LIST_DESCRIPTION_SIZE}
      colorFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_LIST_DESCRIPTION_COLOR}
      fontWeightFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_LIST_DESCRIPTION_FONT_WEIGHT}
      isV2Template={isV2Template}
      {...props}
    />
  </>
)

export const AlbumListMobileFieldsBlock = ({ formatMessage }) => (
  <>
    <SubHeader>{formatMessage({ id: 'styles.title.albumPage.title' })}</SubHeader>
    <StylesFontSizeSlider name={STYLE_FEATURE_PROPERTIES.ALBUM_LIST_MOBILE_TITLE_SIZE} />
    <SubHeader>{formatMessage({ id: 'styles.title.albumPage.description' })}</SubHeader>
    <StylesFontSizeSlider name={STYLE_FEATURE_PROPERTIES.ALBUM_LIST_MOBILE_DESCRIPTION_SIZE} />
  </>
)

export const PhotosFieldsBlock = ({ formatMessage, isV2Template, ...props }) => (
  <>
    <SubHeader>{formatMessage({ id: 'styles.title.photoPage.title' })}</SubHeader>
    <DefaultFieldsBlock
      fontFieldName={STYLE_FEATURE_PROPERTIES.PHOTOS_TITLE_FONT_NAME}
      fontIdFieldName={STYLE_FEATURE_PROPERTIES.PHOTOS_TITLE_FONT_ID}
      sizeFieldName={STYLE_FEATURE_PROPERTIES.PHOTOS_TITLE_SIZE}
      colorFieldName={STYLE_FEATURE_PROPERTIES.PHOTOS_TITLE_COLOR}
      fontWeightFieldName={STYLE_FEATURE_PROPERTIES.PHOTOS_TITLE_FONT_WEIGHT}
      isV2Template={isV2Template}
      {...props}
    />
    <SubHeader>{formatMessage({ id: 'styles.title.albumPage.description' })}</SubHeader>
    <DefaultFieldsBlock
      fontFieldName={STYLE_FEATURE_PROPERTIES.PHOTOS_DESCRIPTION_FONT_NAME}
      fontIdFieldName={STYLE_FEATURE_PROPERTIES.PHOTOS_DESCRIPTION_FONT_ID}
      sizeFieldName={STYLE_FEATURE_PROPERTIES.PHOTOS_DESCRIPTION_SIZE}
      colorFieldName={STYLE_FEATURE_PROPERTIES.PHOTOS_DESCRIPTION_COLOR}
      fontWeightFieldName={STYLE_FEATURE_PROPERTIES.PHOTOS_DESCRIPTION_FONT_WEIGHT}
      isV2Template={isV2Template}
      {...props}
    />
    <SubHeader>{formatMessage({ id: 'styles.title.albumPage.note' })}</SubHeader>
    <DefaultFieldsBlock
      fontFieldName={STYLE_FEATURE_PROPERTIES.PHOTOS_PHOTO_NOTE_FONT_NAME}
      fontIdFieldName={STYLE_FEATURE_PROPERTIES.PHOTOS_PHOTO_NOTE_FONT_ID}
      sizeFieldName={STYLE_FEATURE_PROPERTIES.PHOTOS_PHOTO_NOTE_SIZE}
      colorFieldName={STYLE_FEATURE_PROPERTIES.PHOTOS_PHOTO_NOTE_COLOR}
      fontWeightFieldName={STYLE_FEATURE_PROPERTIES.PHOTOS_PHOTO_NOTE_FONT_WEIGHT}
      isV2Template={isV2Template}
      {...props}
    />
  </>
)

export const PhotosMobileFieldsBlock = ({ formatMessage }) => (
  <>
    <SubHeader>{formatMessage({ id: 'styles.title.photoPage.title' })}</SubHeader>
    <StylesFontSizeSlider name={STYLE_FEATURE_PROPERTIES.PHOTOS_MOBILE_TITLE_SIZE} />
    <SubHeader>{formatMessage({ id: 'styles.title.albumPage.description' })}</SubHeader>
    <StylesFontSizeSlider name={STYLE_FEATURE_PROPERTIES.PHOTOS_MOBILE_DESCRIPTION_SIZE} />
    <SubHeader>{formatMessage({ id: 'styles.title.albumPage.imgDescription' })}</SubHeader>
    <StylesFontSizeSlider name={STYLE_FEATURE_PROPERTIES.PHOTOS_MOBILE_PHOTO_NOTE_SIZE} />
  </>
)

export const ImageDetailBlock = ({ formatMessage, isV2Template, ...props }) => {
  const headerNavigationStyleItems = [
    {
      value: IMAGE_DETAIL_TEXT_TYPE.TEXT,
      label: 'styles.title.imageDetail.text',
    },
    {
      value: IMAGE_DETAIL_TEXT_TYPE.ICON,
      label: 'styles.title.imageDetail.icon',
    },
  ]

  return (
    <>
      <StylesRadioGroup
        name={STYLE_FEATURE_PROPERTIES.IMAGE_DETAIL_TEXT_TYPE}
        label='styles.title.imageDetail.text.type'
        items={headerNavigationStyleItems}
      />

      <DefaultFieldsBlock
        fontFieldName={STYLE_FEATURE_PROPERTIES.IMAGE_DETAIL_TEXT_NAME}
        fontIdFieldName={STYLE_FEATURE_PROPERTIES.IMAGE_DETAIL_TEXT_FONT_ID}
        sizeFieldName={STYLE_FEATURE_PROPERTIES.IMAGE_DETAIL_TEXT_SIZE}
        colorFieldName={STYLE_FEATURE_PROPERTIES.IMAGE_DETAIL_TEXT_COLOR}
        fontWeightFieldName={STYLE_FEATURE_PROPERTIES.IMAGE_DETAIL_TEXT_FONT_WEIGHT}
        isV2Template={isV2Template}
        {...props}
      />

      <StylesColorPicker
        name={STYLE_FEATURE_PROPERTIES.IMAGE_DETAIL_BACKGROUND_COLOR}
        customText='backgroundColor'
      />
    </>
  )
}

export const ImageDetailMobileBlock = () => (
  <>
    <StylesFontSizeSlider name={STYLE_FEATURE_PROPERTIES.IMAGE_DETAIL_MOBILE_TEXT_SIZE} />
  </>
)

export const Slideshow = ({
  formatMessage,
  updateSlideshow,
  slideshowEnabled,
  goToSlideshowSettings,
}) => {
  const [isChecked, setIsChecked] = useState(slideshowEnabled || false)

  const updateSlideshowF = (checked) => {
    setIsChecked(checked)

    updateSlideshow({
      slideshowEnabled: checked,
    })
  }

  const redirectToConfig = useCallback(() => {
    goToSlideshowSettings()
  }, [goToSlideshowSettings])

  return (
    <>
      <WrapperHeader>
        <Header>
          {formatMessage({
            id: 'tabs.tab.slideshow',
          })}
        </Header>
        <Toggle checked={isChecked} onChange={(e, data) => updateSlideshowF(data.checked)} />
      </WrapperHeader>

      <Description>{formatMessage({ id: 'styles.title.slideshow.description' })}</Description>

      <WrapperButton>
        <Button
          type='button'
          view='primary'
          onClick={redirectToConfig}
          content={formatMessage({ id: 'styles.title.slideshow.button' })}
        />
      </WrapperButton>

      <WrapperImg>
        <Image
          src='/img/slideshow-preview.jpg'
          imgStyle={{ objectFit: 'contain' }}
          alt='slideshow-preview'
        />
      </WrapperImg>
    </>
  )
}

export const ContactFieldsBlock = (props) => (
  <DefaultFieldsBlock
    fontFieldName={STYLE_FEATURE_PROPERTIES.CONTACTS_FONT_NAME}
    fontIdFieldName={STYLE_FEATURE_PROPERTIES.CONTACTS_FONT_ID}
    sizeFieldName={STYLE_FEATURE_PROPERTIES.CONTACTS_SIZE}
    colorFieldName={STYLE_FEATURE_PROPERTIES.CONTACTS_COLOR}
    fontWeightFieldName={STYLE_FEATURE_PROPERTIES.CONTACTS_FONT_WEIGHT}
    isV2Template={props.isV2Template}
    {...props}
  />
)

export const TextFieldsBlock = (props) => (
  <DefaultFieldsBlock
    fontFieldName={STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_FONT_NAME}
    fontIdFieldName={STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_FONT_ID}
    sizeFieldName={STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_SIZE}
    colorFieldName={STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_COLOR}
    fontWeightFieldName={STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_FONT_WEIGHT}
    isV2Template={props.isV2Template}
    {...props}
  />
)

export const BackGroundFieldsBlock = () => (
  <StylesColorPicker name={STYLE_FEATURE_PROPERTIES.BACKGROUND_COLOR} />
)

export const HoverFieldsBlock = () => (
  <StylesColorPicker name={STYLE_FEATURE_PROPERTIES.HOVER_COLOR} />
)

export const BurgerFieldsBlock = () => (
  <StylesColorPicker name={STYLE_FEATURE_PROPERTIES.BURGER_COLOR} />
)

export const LoaderAnimationBlock = (props) => (
  <CustomLoaderPicker
    colorFieldName={STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_COLOR}
    variantFieldName={STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_VARIANT}
    customFieldName={STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_CUSTOM}
    {...props}
  />
)

export const SocialLinksBlock = (props) => (
  <SocialLinks
    iconTypeName={STYLE_FEATURE_PROPERTIES.FOOTER_ICON_TYPE}
    iconColorName={STYLE_FEATURE_PROPERTIES.FOOTER_SOCIAL_ICON_COLOR}
    socialLinksName={STYLE_FEATURE_PROPERTIES.SOCIAL_LINKS}
    {...props}
  />
)

export const FooterMobileFieldsBlock = ({ formatMessage }) => {
  const layoutAlignOptions = [
    {
      text: formatMessage({ id: 'styles.footer.layoutAlign.left' }),
      value: LAYOUT_ALIGN_TYPE.LEFT,
    },
    {
      text: formatMessage({ id: 'styles.footer.layoutAlign.right' }),
      value: LAYOUT_ALIGN_TYPE.RIGHT,
    },
    {
      text: formatMessage({ id: 'styles.footer.layoutAlign.center' }),
      value: LAYOUT_ALIGN_TYPE.CENTER,
    },
  ]

  return (
    <>
      <StylesLayoutAlignDropdown
        options={layoutAlignOptions}
        label='styles.footer.layoutAlignMobile'
        name={STYLE_FEATURE_PROPERTIES.FOOTER_MOBILE_LAYOUT_ALIGN}
      />
      <StylesFontSizeSlider
        formatMessageId={'styles.inputs.navigationFontSize.label'}
        name={STYLE_FEATURE_PROPERTIES.FOOTER_MOBILE_SIZE}
      />
    </>
  )
}
