import React, { useState } from 'react'
import { injectIntl, intlShape } from 'react-intl'
import { useQuery, useMutation } from 'react-apollo'
import isEmpty from 'lodash/isEmpty'
import websiteMetaGetQuery from '@graphql/gql/website/websiteMetaGet.gql'
import websiteMetaUpdateMutation from '@graphql/gql/website/websiteMetaUpdate.gql'
import { HeaderStyled } from 'packages/components/layout/nodes'
import VisibilitySection from './sections/VisibilitySection'
import FreeSection from './sections/FreeSection'
import PremiumSection from './sections/PremiumSection'
import Loader from '../loader/Loader'
import PrivacySection from './sections/PrivacySection'
import { useIsMobile } from 'packages/helpers/useWindowSize'
import {
  Container,
  ContentWrapper,
  Footer,
  Header,
  Settings,
  WrapperSettings,
  WrapperTabs,
} from './nodes'
import { useSubscription } from '@configurator/providers/subscription'
import PermissionsOverlay from 'packages/components/permissionsOverlay/permissionsOverlay'
import { Accordion } from 'packages/components/Accordion'
import { Tabs } from 'packages/components/shared'

const tabs = [
  { id: 1, title: 'website.settings.visibility.title', slug: 'visibility' },
  { id: 2, title: 'website.settings.freeSubDomain.title', slug: 'subdomain' },
  { id: 3, title: 'website.settings.customDomain.title', slug: 'domain' },
  {
    id: 4,
    title: 'website.settings.privacy.title',
    dataIntercomTarget: 'PrivacyGdpr',
    slug: 'privacy',
  },
]

const WebsiteSettings = ({ intl: { formatMessage } }) => {
  const { loading, data: { websiteMetaGet } = {} } = useQuery(websiteMetaGetQuery, {
    fetchPolicy: 'network-only',
  })
  const [websiteMetaUpdate, { error: metaUpdateError, loading: metaUpdateLoading }] =
    useMutation(websiteMetaUpdateMutation)

  const [customLoading, setCustomLoading] = useState(false)
  const [activeSlug, setActiveSlug] = useState(tabs[0].slug)

  const onSelectAccordionItem = (slug) => {
    setActiveSlug(slug === activeSlug ? null : slug)
  }

  const isMobile = useIsMobile()

  const { permissions } = useSubscription()

  const [activeTab, setActiveTab] = useState()

  if (loading) {
    return <Loader />
  }

  if (isEmpty(websiteMetaGet) || !isEmpty(websiteMetaGet.errors) || !isEmpty(metaUpdateError)) {
    return <div>Error</div>
  }

  const { data } = websiteMetaGet

  // toggle visibility
  const toggleVisibility = async () => {
    setCustomLoading(true)
    await websiteMetaUpdate({
      variables: {
        websiteId: data.id,
        visible: !data.visible,
      },
      refetchQueries: [
        {
          query: websiteMetaGetQuery,
          variables: {},
        },
      ],
    })
    setCustomLoading(false)
  }

  // toggle cookie consent solution
  const toggleCookieSolution = () => {
    // add loader
    websiteMetaUpdate({
      variables: {
        websiteId: data.id,
        cookie: !data.cookie,
      },
      refetchQueries: [
        {
          query: websiteMetaGetQuery,
          variables: {},
        },
      ],
    })
  }

  // toggle remove branding
  const toggleRemoveBranding = () => {
    // add loader
    websiteMetaUpdate({
      variables: {
        websiteId: data.id,
        branding: !data.branding,
      },
      refetchQueries: [
        {
          query: websiteMetaGetQuery,
          variables: {},
        },
      ],
    })
  }

  // toggle change domain
  const changeFreeDomain = async ({ domain }) => {
    // add loader
    return websiteMetaUpdate({
      variables: {
        websiteId: data.id,
        domain,
      },
      refetchQueries: [
        {
          query: websiteMetaGetQuery,
          variables: {},
        },
      ],
    }).then((mutationResult) => {
      const mutationError = mutationResult?.data?.websiteMetaUpdate?.errors?._error
      if (mutationError) {
        throw new Error(mutationError)
      }
    })
  }

  const toggleCookieBar = () => {
    // add loader
    websiteMetaUpdate({
      variables: {
        websiteId: data.id,
        displayCookieBanner: !data.displayCookieBanner,
      },
      refetchQueries: [
        {
          query: websiteMetaGetQuery,
          variables: {},
        },
      ],
    })
  }

  const toggleBlockFonts = () => {
    // add loader
    websiteMetaUpdate({
      variables: {
        websiteId: data.id,
        blockFonts: !data.blockFonts,
      },
      refetchQueries: [
        {
          query: websiteMetaGetQuery,
          variables: {},
        },
      ],
    })
  }

  const currentYear = new Date().getFullYear()

  const handleRenderContent = () => {
    switch (activeTab) {
      case 'visibility':
        return (
          <PermissionsOverlay isAllowed={permissions.WEBSITE_VISIBILITY}>
            <VisibilitySection
              values={{
                visible: data.visible,
                cookie: data.cookie,
                branding: data.branding,
              }}
              loading={metaUpdateLoading || customLoading}
              onChange={{ toggleVisibility, toggleCookieSolution, toggleRemoveBranding }}
            />
          </PermissionsOverlay>
        )

      case 'subdomain':
        return <FreeSection domain={data.domain} changeDomain={changeFreeDomain} />

      case 'domain':
        return <PremiumSection freeDomain={data.domain} websiteId={data.id} />

      case 'privacy':
        return (
          <PrivacySection
            onChange={{ toggleCookieBar, toggleBlockFonts }}
            values={{
              cookies: data.displayCookieBanner,
              fonts: data.blockFonts,
            }}
          />
        )

      default:
        return null
    }
  }

  const accordionItems = [
    {
      slug: 'visibility',
      title: formatMessage({
        id: 'website.settings.visibility.title',
      }),
      content: (
        <PermissionsOverlay isAllowed={permissions.WEBSITE_VISIBILITY}>
          <VisibilitySection
            isMobile
            values={{
              visible: data.visible,
              cookie: data.cookie,
              branding: data.branding,
            }}
            onChange={{ toggleVisibility, toggleCookieSolution, toggleRemoveBranding }}
          />
        </PermissionsOverlay>
      ),
    },
    {
      slug: 'subdomain',
      title: formatMessage({
        id: 'website.settings.freeSubDomain.title',
      }),
      content: <FreeSection domain={data.domain} changeDomain={changeFreeDomain} isMobile />,
    },
    {
      slug: 'domain',
      title: formatMessage({
        id: 'website.settings.customDomain.title',
      }),
      content: <PremiumSection freeDomain={data.domain} websiteId={data.id} isMobile />,
    },
    {
      slug: 'privacy',
      title: formatMessage({
        id: 'website.settings.privacy.title',
      }),
      content: (
        <PrivacySection
          isMobile
          onChange={{ toggleCookieBar, toggleBlockFonts }}
          values={{
            cookies: data.displayCookieBanner,
            fonts: data.blockFonts,
          }}
        />
      ),
    },
  ]

  return (
    <React.Fragment>
      <HeaderStyled backArrow />

      <Header>{formatMessage({ id: 'website.settings' })}</Header>

      <WrapperSettings>
        <Container>
          {isMobile ? (
            <Settings>
              <Accordion
                items={accordionItems}
                active={activeSlug}
                setActive={onSelectAccordionItem}
              />
            </Settings>
          ) : (
            <Settings>
              <WrapperTabs>
                <Tabs tabs={tabs} active={activeTab} setActive={setActiveTab} />
              </WrapperTabs>

              <ContentWrapper>{handleRenderContent()}</ContentWrapper>
            </Settings>
          )}
        </Container>
        <Footer>
          {formatMessage({ id: 'website.footer' })} @ {currentYear}
        </Footer>
      </WrapperSettings>
    </React.Fragment>
  )
}

WebsiteSettings.propTypes = {
  intl: intlShape.isRequired,
}

WebsiteSettings.defaultProps = {}

export default injectIntl(WebsiteSettings)
