import PropTypes from 'prop-types'
import React, { useState, useMemo } from 'react'
import { intlShape, injectIntl, FormattedMessage } from 'react-intl'
import chunk from 'lodash/chunk'
import { Button } from 'packages/components/buttons'
import {
  FEATURE_PROPERTY_KEYS,
  PERMISSIONS_ALL_PORTFOLIO_TEMPLATES,
  PORTFOLIO_TEMPLATES,
} from 'packages/enum'
import Image from 'packages/components/gatsby-image'
import ChangeTemplateModal from '@configurator/components/modals/changeTemplateModal'
import {
  HeaderBlock,
  Container,
  Row,
  Item,
  ItemHeader,
  ImageWrapper,
  ImageOverlay,
  Content,
  SelectedBlock,
  // ProBlock,
  SlideshowNotification,
  // FreeBlock,
} from './nodes'
import { makeTemplatesArray } from './const'
import { HeaderStyled } from 'packages/components/layout/nodes'
import { useSubscription } from '@configurator/providers/subscription'
import PermissionsOverlay from 'packages/components/permissionsOverlay/permissionsOverlay'
import { IconWrapper } from '@configurator/components/navigation/pageLinkNode/nodes'
import { SlideshowIcon } from 'packages/components/icons'

const aspectRatio = 1.291248207

const TemplateItem = ({
  genericName,
  src,
  header,
  selected,
  updateTemplate,
  previewUrl,
  slideshowEnabled,
  availableTemplates,
  // eslint-disable-next-line no-unused-vars
  // planType,
  // isFree,
  websiteId,
  websiteShareFeaturesCreate,
  intl: { formatMessage },
}) => {
  const [openDialog, setOpenDialog] = useState(false)
  const formattedHeader = formatMessage({ id: header })
  const permissionsRegex = new RegExp(
    `^(${genericName}|${PERMISSIONS_ALL_PORTFOLIO_TEMPLATES})$`,
    'i'
  )
  let block = null

  // if (isFree) {
  //   block = <FreeBlock>FREE</FreeBlock>
  // } else {
  //   block = <ProBlock>PRO</ProBlock>
  // }

  return (
    <>
      <Item>
        <ImageWrapper selected={selected}>
          {selected && (
            <SelectedBlock>
              <FormattedMessage id='template.selectedTemplate' />
            </SelectedBlock>
          )}
          {block}
          <Image
            fluid={{
              src,
              aspectRatio,
            }}
            alt=''
            fadeIn
            loading='lazy'
            backgroundColor='#f6f6f6'
          />
          <ImageOverlay selected={selected}>
            <PermissionsOverlay
              isAllowed={Boolean(availableTemplates?.find((el) => permissionsRegex.test(el)))}
            >
              <Button
                content={formatMessage(
                  { id: 'template.button.select' },
                  { template: formattedHeader }
                )}
                view='primary'
                onClick={() => setOpenDialog(true)}
              />
            </PermissionsOverlay>
            <Button
              content={formatMessage(
                { id: 'template.button.preview' },
                { template: formattedHeader }
              )}
              view='secondaryGray'
              as='a'
              href={previewUrl}
              target='_blank'
              rel='noopener noreferrer'
            />
          </ImageOverlay>
        </ImageWrapper>
        <Content>
          <ItemHeader>{formattedHeader}</ItemHeader>
          {slideshowEnabled && (
            <SlideshowNotification>
              <IconWrapper>
                <SlideshowIcon newVariant={true} />
              </IconWrapper>
              <span
                dangerouslySetInnerHTML={{
                  __html: formatMessage({
                    id: 'template.slideShowEnabled',
                  }),
                }}
              />
            </SlideshowNotification>
          )}
        </Content>
      </Item>
      {openDialog && (
        <ChangeTemplateModal
          websiteId={websiteId}
          websiteShareFeaturesCreate={websiteShareFeaturesCreate}
          open
          onClickYes={() => {
            updateTemplate()
            setOpenDialog(false)
          }}
          onClose={() => setOpenDialog(false)}
        />
      )}
    </>
  )
}

TemplateItem.propTypes = {
  src: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  genericName: PropTypes.string,
  selected: PropTypes.bool,
  updateTemplate: PropTypes.func.isRequired,
  previewUrl: PropTypes.string.isRequired,
  slideshowEnabled: PropTypes.bool.isRequired,
  availableTemplates: PropTypes.array.isRequired,
  planType: PropTypes.string,
  isFree: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
}

TemplateItem.defaultProps = { selected: false }

const Header = () => (
  <HeaderBlock>
    <Row>
      <h1 data-intercom-target={'ChooseTemplateHeader'}>
        <FormattedMessage id='template.header.main' />
      </h1>
      <FormattedMessage id='template.header.description' />
    </Row>
  </HeaderBlock>
)

const TemplatesComponent = ({ meta, websiteShareFeaturesCreate, updateTemplate, intl }) => {
  const { templateName, features = [], id } = meta
  const {
    info: { planType },
    permissions: { TEMPLATES },
  } = useSubscription()

  const slideshowEnabled = !!(
    features.find((itm) => itm.featureName === FEATURE_PROPERTY_KEYS.slider) || {}
  ).enabled
  const align = meta.align ? meta.align.toUpperCase() : ''

  const templatesArray = makeTemplatesArray({
    updateTemplate,
    templateName,
    align,
    slideshowEnabled,
    planType,
  }).filter((item) => item.genericName !== PORTFOLIO_TEMPLATES.BERLIN)

  const templatesByColor = useMemo(() => chunk(templatesArray, 2), [templatesArray])

  return (
    <>
      <HeaderStyled backArrow />
      <Container>
        <Header />
        {templatesByColor.map((pair) => (
          <Row key={pair[0].header}>
            {pair.map((item) => {
              return (
                <TemplateItem
                  key={item.header}
                  src={item.imageUrl}
                  genericName={item.genericName}
                  header={item.header}
                  description={item.description}
                  selected={item.selected}
                  updateTemplate={() => {
                    item.updateTemplate({
                      slideshowEnabled,
                    })
                  }}
                  previewUrl={item.previewUrl}
                  intl={intl}
                  slideshowEnabled={item.slideshowEnabled}
                  availableTemplates={TEMPLATES}
                  planType={planType}
                  isFree={item.isFree}
                  websiteId={id}
                  websiteShareFeaturesCreate={websiteShareFeaturesCreate}
                />
              )
            })}
          </Row>
        ))}
      </Container>
    </>
  )
}

TemplatesComponent.propTypes = {
  updateTemplate: PropTypes.func,
  meta: PropTypes.object,
  arrowBack: PropTypes.bool,
  history: PropTypes.object,
}

TemplatesComponent.defaultProps = {
  updateTemplate: () => {},
  meta: {},
  arrowBack: true,
  history: {},
}

export const Templates = injectIntl(TemplatesComponent)
